<nz-result
  [nzTitle]="noDataTitle"
  nzStatus="warning"
  [nzSubTitle]="noDataSubTitle"
>
  <div nz-result-extra>
    <button nz-button *ngIf="showPrimary" nzType="primary" (click)="clickPrimary()">{{primaryButtonText}}</button>
    <button nz-button *ngIf="showSecondary" (click)="clickSecondary()">{{secondaryButtonText}}</button>
  </div>
</nz-result>
