
import {Exclude, Expose, Type} from 'class-transformer';
import {Media} from '../media/media.entity';
import {WorkCenter} from '../work-center/work-center.entity';
import {IAttachable} from '../../interfaces/holosen-attachable-entity';
import {BaseEntity} from '../base/base-entity';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {HolosenEntityChild} from '../../decorators/HolosenEntityChild';
import {IsNotEmpty} from 'class-validator';

export enum OperationType {
  MAIN = <any>1,
  AUXILIARY = <any>2,
  OTHER = <any>3,
}

export enum OperationExecutor {
  IN_HOUSE = <any>1,
  OUTSOURCE = <any>2,
  BOTH = <any>3,
}


@Exclude()
@HolosenEntityModel({
  apiPrefix: 'capacity/operations',
  storeName: 'operations'
})
export class Operation extends BaseEntity implements IAttachable {

  @Expose()
  @IsNotEmpty()
  name: string;

  @Expose()
  @IsNotEmpty()
  code: string;

  @Expose()
  @IsNotEmpty()
  color: string;

  @Expose()
  @IsNotEmpty()
  type: number;

  @Expose()
  description: string;

  @Expose()
  @IsNotEmpty()
  executor: number;

  @Expose()
  @Type(() => Media)
  @HolosenEntityChild(() => Media)
  media: Array<Media> = [];

  @Expose({name: 'work_center_ids'})
  workCenters: Array<number> = [];


  @Exclude()
  getTypeName = () => {
    let typeName = 'N/A';
    if (this.type) {
      switch (this.type) {
        case OperationType.MAIN: {
          typeName = 'Main';
          break;
        }
        case OperationType.AUXILIARY: {
          typeName = 'Auxiliary';
          break;
        }
        case OperationType.OTHER: {
          typeName = 'Other';
          break;
        }
      }
    }
    return typeName;
  };

  @Exclude()
  getExecutorName = () => {
    let typeName = 'N/A';
    if (this.type) {
      switch (this.type) {
        case OperationExecutor.IN_HOUSE: {
          typeName = 'In-House';
          break;
        }
        case OperationExecutor.OUTSOURCE: {
          typeName = 'Outsource';
          break;
        }
        case OperationExecutor.BOTH: {
          typeName = 'Both';
          break;
        }
      }
    }
    return typeName;
  };


}

