import {Injectable} from '@angular/core';
import {HolosenStateManagementService} from '../state-management/services/holosen-state-management.service';
import {HolosenLayoutService} from '../layout/services/holosen-layout.service';
import {combineLatest, defer, of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AppConfig} from '../app.config';
import {HolosenLocalStorageService} from '../core/services/holosen-local-storage.service';
import {tap} from 'rxjs/operators';
import {PlanbuTranslationService} from '../core/i18n/planbu-translation.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(private stateManager: HolosenStateManagementService,
              private layoutService: HolosenLayoutService,
              private translateService: TranslateService,
              private storageService: HolosenLocalStorageService,
              private planbuTranslationService: PlanbuTranslationService) {
  }

  initApp = () => {
    return new Promise((resolve) => {
      combineLatest([
        this.initLayoutSubscription(),
        this.initNormalizrSchemas(),
        this.initTranslations()
      ]).subscribe(_ => setTimeout(resolve, 0));
    });
  };

  initLayoutSubscription = () => {
    return this.layoutService.subscribeToSidebarStatus();
  };

  initNormalizrSchemas = () => {
    return defer(() => of(this.stateManager.initEntitySchemas()));
  };

  initTranslations = () => {

    return this.storageService.get(AppConfig.STORAGE_KEYS.LANGUAGE_KEY).pipe(
      tap((lang) => {
          if (!!lang) {
            this.planbuTranslationService.translate(lang);
          } else {
            this.planbuTranslationService.translate('en');
          }
        }
      )
    );
  };
}
