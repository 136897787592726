import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LAYOUT_COLORS} from '../../common/colors';

@Component({
  selector: 'app-planbu-select-color',
  templateUrl: './planbu-select-color.component.html',
  styleUrls: ['./planbu-select-color.component.less']
})
export class PlanbuSelectColorComponent implements OnInit {


  @Input()
  selectedColor;

  @Output()
  onColorSelect = new EventEmitter<string>();

  colors = LAYOUT_COLORS;

  constructor() {
  }

  ngOnInit() {
  }

  selectColor = (color) => {
    this.onColorSelect.emit(color);
  };

}
