import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, switchMap} from 'rxjs/operators';
import {HolosenLocalStorageService} from '../services/holosen-local-storage.service';
import {AppConfig} from '../../app.config';
import {TokenData} from '../types/token-data';
import * as HTTP_STATUS from 'http-status-codes';


export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {


  constructor(private storageService: HolosenLocalStorageService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  private httpError = new BehaviorSubject<any>({});

  get onHttpError() {
    return this.httpError.asObservable();
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(InterceptorSkipHeader);
      return next.handle(req.clone({headers})).pipe(
        catchError(err => {
          return this.handleError(err);
        })
      );
    }

    return this.storageService.get(AppConfig.STORAGE_KEYS.TOKEN_STORAGE_KEY).pipe(
      // map(tokenData => JSON.parse(tokenData)),
      switchMap((tokenData: TokenData) => {

          let interceptedRequest;
          if (tokenData) {
            const authorizationValue = tokenData.token_type + ' ' + tokenData.access_token;
            interceptedRequest = req.clone({
              setHeaders: {
                Accept: 'application/json',
                Authorization: authorizationValue
              }
            });

          } else {
            interceptedRequest = req;
          }


          return next.handle(interceptedRequest).pipe(
            catchError(err => {
              return this.handleError(err);
            })
          );
        }
      )
    );

  }


  private handleError = (err): Observable<any> => {

    if (err instanceof HttpErrorResponse) {

      this.httpError.next(err);

      switch (err.status) {

        case 0: {
          return this.handleUnknownError(err);
        }
        case HTTP_STATUS.NOT_FOUND: {
          return this.handleNotFoundError(err);
        }
        case HTTP_STATUS.BAD_REQUEST: {
          return this.handleBadRequestError(err);
        }
        case HTTP_STATUS.UNAUTHORIZED: {
          return this.handleUnauthorizedError(err);
        }
        default: {
          return this.handleUnknownError(err);
        }
      }
    } else {
      return this.handleNonHttpErrors(err);
    }
  };


  private handleUnauthorizedError = (error: HttpErrorResponse): Observable<any> => {
    this.storageService.delete(AppConfig.STORAGE_KEYS.TOKEN_STORAGE_KEY, true);
    console.log('handleUnauthorizedError');
    this.router.navigate(['/auth/login'], {queryParams: {returnUrl: this.route.snapshot.queryParamMap.has('returnUrl') ? this.route.snapshot.queryParamMap.get('returnUrl') : null}});
    // this.router.navigate(['/auth/login'], {queryParams: {returnUrl: this.router.routerState.snapshot.url}});
    return throwError(error);
  };

  private handleBadRequestError = (error: HttpErrorResponse): Observable<any> => {
    console.log('handleBadRequestError');
    return throwError(error);
  };

  private handleUnknownError = (error: any): Observable<any> => {
    console.log('handleUnknownError');
    return throwError(error);
  };


  private handleNonHttpErrors = (error: any) => {
    console.log('handleNonHttpErrors');
    return throwError({message: 'A Non-HTTP Error Recieved', error});
  };

  private handleNotFoundError = (error: HttpErrorResponse) => {
    return throwError({message: 'Requested entities not found', status: 404});
  };


}
