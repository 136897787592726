import {Exclude, Expose, Transform} from 'class-transformer';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {BaseEntity} from '../base/base-entity';
import * as moment from 'moment';
import {IsNotEmpty} from 'class-validator';


export function toDateObject(value) {
  return moment().hour(value.hours).minute(value.minutes).toDate();
}

export function toTimeObject(value: Date): { hours, minutes } {
  return {
    hours: value.getHours(),
    minutes: value.getMinutes()
  };
}


@Exclude()
@HolosenEntityModel({
  apiPrefix: 'capacity/shifts',
  storeName: 'shifts'
})
export class Shift extends BaseEntity {

  @Exclude()
  private static readonly DEFAULTS = {
    START_HOUR: 8,
    START_MINUTE: 30,
    END_HOUR: 17,
    END_MINUTE: 0
  };


  @Expose()
  @IsNotEmpty()
  name = null;

  @Expose()
  description;

  @Expose()
  days: number[] = [];


  @Expose()
  // @Transform(toDateObject, {toClassOnly: true})
  // @Transform(toTimeObject, {toPlainOnly: true})
  @Transform((time: Date) => moment(time).format('HH:mm:ss'), {toPlainOnly: true, groups: ['toAPI']})
  @Transform((time: string) => {
    console.log('start_time');
    console.log(`[time] `, time);
    return moment(time, 'HH:mm:ss').toDate();
  }, {toClassOnly: true, groups: ['fromAPI']})
  startTime: Date = moment().hour(Shift.DEFAULTS.START_HOUR).minute(Shift.DEFAULTS.START_MINUTE).toDate();

  @Expose()
  // @Transform(toDateObject, {toClassOnly: true})
  // @Transform(toTimeObject, {toPlainOnly: true})
  @Transform((time: Date) => moment(time).format('HH:mm:ss'), {toPlainOnly: true, groups: ['toAPI']})
  @Transform((time: string) => moment(time, 'HH:mm:ss').toDate(), {toClassOnly: true, groups: ['fromAPI']})
  endTime: Date = moment().hour(Shift.DEFAULTS.END_HOUR).minute(Shift.DEFAULTS.END_MINUTE).toDate();

}


export const SHIFT_DAY_LIST: { dayIndex: number; name: string; }[] = [
  {
    dayIndex: 0,
    name: 'COMMON.DAYS.MONDAY'
  },
  {
    dayIndex: 1,
    name: 'COMMON.DAYS.TUESDAY'
  },
  {
    dayIndex: 2,
    name: 'COMMON.DAYS.WEDNESDAY'
  },
  {
    dayIndex: 3,
    name: 'COMMON.DAYS.THURSDAY'
  },
  {
    dayIndex: 4,
    name: 'COMMON.DAYS.FRIDAY'
  },
  {
    dayIndex: 5,
    name: 'COMMON.DAYS.SATURDAY'
  },
  {
    dayIndex: 6,
    name: 'COMMON.DAYS.SUNDAY'
  }
];







