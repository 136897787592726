import {en_US} from 'ng-zorro-antd/i18n'

export default {
  ...en_US,
  locale: 'en',
  Pagination: {
    ...en_US.Pagination,
    itemsPerPage: '/ page'
  }
}
