import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthenticatedLayoutComponent} from '../layout/authenticated/authenticated-layout.component';
import {Gatekeeper} from '../core/guards/gatekeeper.service';

const authenticatedRoutes: Routes = [
  {
    path: '',
    component: AuthenticatedLayoutComponent,
    children: [
      {path: '', pathMatch: 'full', redirectTo: '/dashboard'},
      {
        path: 'dashboard',
        loadChildren: () => import('../modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {path: 'sales', loadChildren: () => import('../modules/sales/sales.module').then(m => m.SalesModule)},
      {path: 'capacity', loadChildren: () => import('../modules/capacity/capacity.module').then(m => m.CapacityModule)},
      {
        path: 'inventory',
        loadChildren: () => import('../modules/inventory/inventory.module').then(m => m.InventoryModule)
      },
      {path: 'settings', loadChildren: () => import('../modules/settings/settings.module').then(m => m.SettingsModule)},
      {
        path: 'business-partners',
        loadChildren: () => import('../modules/business-partner/business-partner.module').then(m => m.BusinessPartnerModule)
      },
      {
        path: 'trainings',
        loadChildren: () => import('../modules/training/training.module').then(m => m.TrainingModule)
      },
      {path: 'cpas', loadChildren: () => import('../modules/cpa/cpa.module').then(m => m.CpaModule)},
      {path: 'changes', loadChildren: () => import('../modules/change/change.module').then(m => m.ChangeModule)},
      {path: 'kpis', loadChildren: () => import('../modules/kpi/kpi.module').then(m => m.KpiModule)},
      {path: 'meetings', loadChildren: () => import('../modules/meeting/meeting.module').then(m => m.MeetingModule)},
      {path: 'risks', loadChildren: () => import('../modules/risk/risk.module').then(m => m.RiskModule)},
      {path: 'quality-objectives', loadChildren: () => import('../modules/quality-objective/quality-objective.module').then(m => m.QualityObjectiveModule)},
    ],
    canActivate: [Gatekeeper],
    canActivateChild: [Gatekeeper]
  },
];


@NgModule({
  imports: [RouterModule.forChild(authenticatedRoutes)],
  exports: [RouterModule]
})
export class AuthenticatedRoutingModule {
}
