import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {NavigationEnd, Router, RouterLinkActive} from '@angular/router';
import {debounceTime, filter} from 'rxjs/operators';


export interface MenutItem {
  text: string;
  url?: string;
  icon?: string;
  children?: Array<MenutItem>;

}

@Component({
  selector: 'app-side-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuItemComponent implements OnInit, AfterViewInit {

  private _menuItem: MenutItem;


  get menuItem(): MenutItem {
    return this._menuItem;
  }

  @Input()
  set menuItem(value: MenutItem) {
    setTimeout(() => {
      this._menuItem = value;
      this.cdr.detectChanges();

      setTimeout(() => {
        this.checkIsActive();
      }, 0);

    }, 0);
  }

  @ViewChildren(RouterLinkActive)
  routerLinkList: QueryList<RouterLinkActive>;

  isActive = false;

  constructor(private router: Router, private cdr: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.subscribeToRouterEvents();
  }

  ngAfterViewInit(): void {
  }

  subscribeToRouterEvents = () => {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      debounceTime(50)
    ).subscribe(_ => this.checkIsActive());
  };

  checkIsActive = () => {
    this.isActive = this.routerLinkList.reduce((prevValue: boolean, curValue: RouterLinkActive) => {
      prevValue = prevValue || curValue.isActive;
      return prevValue;
    }, false);
    this.cdr.detectChanges();
  };


}
