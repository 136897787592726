import {Directive, ElementRef, HostListener, OnInit} from '@angular/core';

@Directive({
  selector: '[planbuPositiveIntegerInput]'
})
export class PlanbuPositiveIntegerInputDirective implements OnInit{

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    console.log('PlanbuPositiveIntegerInputDirective');
  }
  @HostListener('input', ['$event'])
  onChange = (event) => {
    console.log(`[eventa] `, event);
  }



}
