import {Exclude} from 'class-transformer';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {StockItem} from '../stock-item/stock-item.entity';


@Exclude()
@HolosenEntityModel({
  apiPrefix: 'inventory/works-in-process',
  storeName: 'worksInProcess'
})
export class WorkInProcess extends StockItem {

}

