<div class="app-header">
  <div class="planbu-flex-row">
    <div>
      <span class="header-trigger" (click)="toggleSidebarCollapse()">
        <i class="trigger"
           nz-icon
           [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
        ></i>
      </span>
    </div>
    <div class="sidebar-logo">
      <a [routerLink]="['/']">
        <!--        <img src="https://ng.ant.design/assets/img/logo.svg" alt="logo">-->
        <h1>planbu</h1>
      </a>
    </div>
    <div class="planbu-flex-width-fill-remaining"></div>

    <div class="header-actions" *planbuVar="(currentLanguage | async) as curLan">
      <nz-avatar
        style="margin-right: 7px; cursor: pointer"
        [nzText]="(curLan === 'en' ? 'tr' : 'en').toUpperCase()"
        (click)="changeLanguage(curLan === 'en' ? 'tr' : 'en' )"
      >
      </nz-avatar>
      <a nz-dropdown [nzDropdownMenu]="menu" [nzOverlayStyle]="{'margin-top': '10px'}">
        <nz-avatar
          class="avatar-dropdown-trigger"
          [nzText]="user?.firstName.substr(0,1).toUpperCase()"

        >
        </nz-avatar>
      </a>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu nzSelectable>
          <li nz-menu-item>{{user?.name}}</li>
          <li nz-menu-divider></li>
          <li nz-menu-item>1st menu item</li>
          <li nz-menu-divider></li>
          <li nz-menu-item (click)="logout()"><i class="fa fa-sm fa-fw fa-sign-out-alt"></i> Logout</li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>
</div>
