import {Exclude} from 'class-transformer';
import {validate as classValidatorValidate, ValidationError, ValidatorOptions} from 'class-validator';
import {Observable} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {map, take} from 'rxjs/operators';
import * as startCase from 'lodash/startCase';

@Exclude()
export abstract class HasError {

  // TODO Tüm HTTP sorgularında validation çağrıldığından emin ol!


  @Exclude()
  protected _errors: ValidationError[];

  @Exclude()
  protected _defaultValidatorOptions: ValidatorOptions;


  @Exclude()
  get errors() {
    return this._errors;
  }


  @Exclude()
  hasErrors(): boolean {
    return this.errors.length > 0;
  };

  @Exclude()
  hasError(property: string): boolean {
    return this.errors.some(error => error.property === property);
  };

  @Exclude()
  hasChildError(child: string, property: string): boolean {
    return this.errors.some(error => error.property === child) && this.errors.find(error => error.property === child).children.some(error => error.property === property);
  };

  @Exclude()
  getError(property: string): ValidationError {
    if (!this.hasError(property)) {
      return null;
    }
    return this.errors.find(error => error.property === property);
  };

  @Exclude()
  getErrorMessage(property: string): string {
    if (!this.hasError(property)) {
      return '';
    }
    return Object.values(this.getError(property).constraints)[0].replace(property, startCase(property));
  };

  @Exclude()
  getChildErrorMessage(child: string, property: string): string {
    if (!this.hasError(child)) {
      return '';
    }

    if (!this.getError(child).children.some(error => error.property === property)) {
      return '';
    }
    return Object.values(this.getError(child).children.find(error => error.property === property).constraints)[0].replace(property, startCase(property));
  };


  @Exclude()
  resetErrors() {
    this._errors = [];
  };

  @Exclude()
  resetError(property: string) {
    this._errors = this.errors.filter(error => error.property !== property);
  };

  @Exclude()
  resetChildError(child: string, property: string) {
    if (this.hasError(child)) {
      const childError = this.getError(child);
      childError.children = childError.children.filter(error => error.property !== property);
    }
  };


  @Exclude()
  validate(validatorOptions?: ValidatorOptions): Observable<boolean> {
    this.resetErrors();
    const options = {...this._defaultValidatorOptions, ...validatorOptions};
    return fromPromise(classValidatorValidate(this, options)).pipe(
      map(errors => {
        if (errors && errors.length > 0) {
          this._errors = errors;
          return false;
        } else {
          return true;
        }
      }),
      take(1)
    );
  };
}
