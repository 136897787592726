<ng-container *ngIf="(entity?.id && !isChanging) else autocompleteTemplate">
  <div class="planbu-flex-row">
    <div class="planbu-flex-width-fill-remaining">
      <div class="selected-entity-container" (click)="toggleIsChanging(true)">
        <ng-container *ngTemplateOutlet="selectedItemTemplate; context: {$implicit: entity}"></ng-container>
      </div>
    </div>
    <div *ngIf="nullable" class="nullable-button" (click)="selectEntity(null)">
      <i class="fa fa-times-circle"></i>
    </div>
  </div>
</ng-container>
<ng-template #autocompleteTemplate>
  <app-planbu-autocomplete

    [dataSource]="autocompleteDataSource"
    [allowClose]="!!entity?.id"
    (itemSelected)="selectEntity($event)"
    (autocompleteClosed)="toggleIsChanging(false)"
  >

    <ng-template let-item>
      <ng-container *ngTemplateOutlet="autocompleteItemTemplate; context: {$implicit: item}"></ng-container>
    </ng-template>
  </app-planbu-autocomplete>
</ng-template>
