import {NgModule} from '@angular/core';
import {AuthenticatedLayoutModule} from './authenticated/authenticated-layout.module';
import {PublicLayoutModule} from './public/public-layout.module';


@NgModule({
  imports: [],
  exports: [
    AuthenticatedLayoutModule,
    PublicLayoutModule
  ],
  declarations: []
})
export class LayoutModule {
}
