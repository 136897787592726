import {Exclude, Expose, Transform, Type} from 'class-transformer';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {BasePlannable} from '../base-plannable/base-plannable.entity';
import {IsNotEmpty} from 'class-validator';
import {User} from '../user/user.entity';
import {Media} from '../media/media.entity';
import {HolosenEntityChild} from '../../decorators/HolosenEntityChild';
import {Activity, ActivityTypesEnum, ACTYIVITY_SOURCE_TYPE} from '../activity/activity.entity';


export const CHANGE_SOURCE_TYPES = {
  CUSTOMER_FEEDBACK: {
    name: 'Müşteri Bildirimi',
    value: 1
  },
  EMPLOYEE_FEEDBACK: {
    name: 'Personel Bildirimi',
    value: 2
  },
  NONCONFORMING_PRODUCT: {
    name: 'Uygunsuz Ürün',
    value: 3
  },
  INNOVATION: {
    name: 'Yenilik',
    value: 4
  },
  INTERNAL_AUDIT: {
    name: 'İç Denetim',
    value: 5
  },
  EXTERNAL_AUDIT: {
    name: 'Dış Denetim',
    value: 6
  },
  OPPORTUNITY: {
    name: 'Fırsat',
    value: 7
  },
  NONCONFORMINTY: {
    name: 'DÖF',
    value: 8
  },
  MANAGEMENT_REVIEW: {
    name: 'YGG',
    value: 9
  },
  KPI: {
    name: 'KPI',
    value: 10
  },
  RISK: {
    name: 'Risk',
    value: 11
  }
}


@Exclude()
@HolosenEntityModel({
  apiPrefix: 'planning/changes',
  storeName: 'changes'
})
export class Change extends BasePlannable {

  @Expose()
  @Type(() => Activity)
  @HolosenEntityChild(() => Activity)
  activities: Array<Activity> = [];

  @Expose()
  @IsNotEmpty()
  @Type(() => User)
  @Transform((trainee) => trainee.id, {groups: ['toAPI']})
  @HolosenEntityChild(() => User)
  responsible: User;

  @Expose()
  @Type(() => Media)
  @HolosenEntityChild(() => Media)
  attachments: Array<Media> = [];


  @Expose()
  @IsNotEmpty()
  source: number;

  @Expose()
  sourceName: string;


}

