import {TimeEvent} from '../../time-event.entity';
import {HolosenEntityModel} from '../../../../decorators/HolosenEntityModel';
import {Expose} from 'class-transformer';
import {OvertimeDetails, TimeEventType} from '../../time-event-details';


@HolosenEntityModel({
  apiPrefix: 'capacity/time-events',
  storeName: 'overtimes'
})
export class Overtime extends TimeEvent {

  @Expose()
  type = TimeEventType.OVERTIME;

  @Expose()
  details: OvertimeDetails = new OvertimeDetails();
}
