import {Exclude, Expose, Transform, Type} from 'class-transformer';
import {Media} from '../media/media.entity';
import {IAttachable} from '../../interfaces/holosen-attachable-entity';
import {BaseEntity} from '../base/base-entity';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {HolosenEntityChild} from '../../decorators/HolosenEntityChild';
import {IsNotEmpty, IsPositive, Min} from 'class-validator';
import {Operation} from '../operation/operation.entity';


@Exclude()
@HolosenEntityModel({
  apiPrefix: 'inventory/bom-operations',
  storeName: 'bomOperations'
})
export class BomOperation extends BaseEntity implements IAttachable {

  @Expose()
  @IsNotEmpty()
  name: string;

  @Expose()
  description: string;

  @Expose()
  @IsNotEmpty()
  @Min(0)
  estimatedLeadTime: number;

  @Expose()
  @IsNotEmpty()
  @Min(0)
  setupTime: number;

  @Expose()
  @IsNotEmpty()
  executionOrder: number = 0;

  @Expose()
  @IsNotEmpty()
  @Type(() => Operation)
  @HolosenEntityChild(() => Operation)
  @Transform(op => op.id, {toPlainOnly: true, groups: ['toAPI']})
  operation: Operation;


  @Expose()
  @Type(() => Media)
  @HolosenEntityChild(() => Media)
  media: Array<Media> = [];


}

