import {Exclude, Expose, Transform, Type} from 'class-transformer';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {StockItem} from '../stock-item/stock-item.entity';
import {IsNotEmpty} from 'class-validator';
import {Unit} from '../unit/unit.entity';
import {HolosenEntityChild} from '../../decorators/HolosenEntityChild';


@Exclude()
@HolosenEntityModel({
  apiPrefix: 'inventory/raw-materials',
  storeName: 'rawMaterials'
})
export class RawMaterial extends StockItem {
  @Expose()
  @IsNotEmpty()
  @Type(() => Unit)
  @Transform(unit => unit.id, {toPlainOnly: true, groups: ['toAPI']})
  @HolosenEntityChild(() => Unit)
  unit: Unit;
}

