import {tr_TR} from 'ng-zorro-antd/i18n'

export default {
  ...tr_TR,
  locale: 'tr',
  Pagination: {
    ...tr_TR.Pagination,
    itemsPerPage: '/ sayfa'
  }
}
