<div>
  <form nz-form
        nzLayout="vertical"
        #upsertForm="ngForm"
  >


    <nz-form-item>
      <nz-form-label>Ek Adı</nz-form-label>
      <nz-form-control
        [nzValidateStatus]="attachment.hasError('name') ? 'error' : ''"
        [nzErrorTip]="attachment.getErrorMessage('name')"
      >
        <input nz-input type="text" name="name" [(ngModel)]="attachment.name">
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Açıklama</nz-form-label>
      <nz-form-control
        [nzValidateStatus]="attachment.hasError('description') ? 'error' : ''"
        [nzErrorTip]="attachment.getErrorMessage('description')"
      >
        <textarea rows="2" nz-input name="addressLine" [(ngModel)]="attachment.description"></textarea>
      </nz-form-control>
    </nz-form-item>


    <nz-form-item>
      <nz-form-label>Dosya</nz-form-label>
      <nz-form-control
        [nzValidateStatus]="attachment.hasError('file') ? 'error' : ''"
        [nzErrorTip]="attachment.getErrorMessage('file')"
      >
        <nz-upload [(nzFileList)]="selectedFile" [nzBeforeUpload]="fileSelected" [nzMultiple]="false">
          <button nz-button><i nz-icon nzType="upload"></i><span>Dosya Seç</span></button>
        </nz-upload>
      </nz-form-control>
    </nz-form-item>


  </form>
</div>

<div *nzModalFooter>
  <button
    nz-button
    nzType="default"
    [disabled]="isUpsertLoading"
    (click)="cancel()"
  >
    {{'COMMON.CANCEL' | translate}}
  </button>
  <button
    nz-button
    nzType="primary"
    (click)="upsertAttachment()"
    [nzLoading]="isUpsertLoading"
  >
    {{(attachment.id ? 'COMMON.UPDATE' : 'COMMON.SAVE') | translate}}
  </button>
</div>
