import {Exclude, Expose, Transform, Type} from 'class-transformer';
import {BaseEntity} from '../base/base-entity';
import {IsDate, IsNotEmpty, ValidateIf} from 'class-validator';
import * as moment from 'moment';


export const PLANNABLE_STATUS = {
  1: {
    value: 1,
    name: 'NEW',
    color: '#5F3EFF'
  },
  2: {
    value: 2,
    name: 'DOING',
    color: '#D6783E'
  },
  3: {
    value: 3,
    name: 'REVIEW',
    color: '#16BAC5'
  },
  4: {
    value: 4,
    name: 'DONE',
    color: '#E0BC08'
  },
  5: {
    value: 5,
    name: 'CANCELLED',
    color: '#e02331'
  },
}


@Exclude()
export abstract class BasePlannable extends BaseEntity {

  @Expose()
  @IsNotEmpty()
  code: string;

  @Expose()
  @IsNotEmpty()
  description: string;


  @Expose()
  @IsNotEmpty()
  @IsDate()
  @Type(() => Date)
  @Transform(value => moment(value, 'YYYY-MM-DD HH:mm:ss').toDate(), {toClassOnly: true})
  @Transform((value: Date) => moment(value).format('YYYY-MM-DD HH:mm:ss'), {toPlainOnly: true})
  plannableCreatedAt: Date;

  @Expose()
  @IsNotEmpty()
  status: number = 1;

  @Expose()
  @IsNotEmpty()
  @IsDate()
  @Type(() => Date)
  @Transform(value => moment(value, 'YYYY-MM-DD HH:mm:ss').toDate(), {toClassOnly: true})
  @Transform((value: Date) => moment(value).format('YYYY-MM-DD HH:mm:ss'), {toPlainOnly: true})
  reviewDeadline: Date;

  @Expose()
  @Type(() => Date)
  @Transform(value => value ? moment(value, 'YYYY-MM-DD HH:mm:ss').toDate() : null, {toClassOnly: true})
  @Transform((value: Date) => value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : null, {toPlainOnly: true})
  reviewedAt: Date;


  @Expose()
  @ValidateIf(o => o.reviewedAt)
  @IsNotEmpty()
  reviewNotes: string;


}

