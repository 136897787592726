import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {HolosenApiResponse} from '../types/holosen-api-response';
import {Observable} from 'rxjs';
import {delay, map} from 'rxjs/operators';

export interface IHolosenHttpServiceResponse {
  ok: boolean;
  message?: string;
  payload?: any;
}

export interface IHolosenHttpRequestOptions {
  headers?: HttpHeaders | { [header: string]: string | string[] };
  observe?: any;
  params?: HttpParams | { [param: string]: string | string[] };
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
}


export enum HTTP_METHODS {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

@Injectable({
  providedIn: 'root'
})
export class HolosenHttpService {

  static readonly HTTP_METHODS = HTTP_METHODS;


  constructor(private http: HttpClient) {
  }

  private query = (config: { method: HTTP_METHODS, url: string, body?: any, options?: IHolosenHttpRequestOptions }): Observable<IHolosenHttpServiceResponse> => {

    let queryType: string;

    let args = [];

    switch (config.method) {
      case HTTP_METHODS.GET: {
        queryType = 'get';
        args = [config.url, config.options];
        break;
      }
      case HTTP_METHODS.POST: {
        queryType = 'post';
        args = [config.url, config.body, config.options];
        break;
      }
      case HTTP_METHODS.PUT: {
        queryType = 'patch';
        args = [config.url, config.body, config.options];
        break;
      }
      case HTTP_METHODS.DELETE: {
        queryType = 'delete';
        args = [config.url, config.body, config.options];
        break;
      }
      default : {
        throw new Error('No compatible HTTP Method name supplied');
      }

    }

    return this.http[queryType]<HolosenApiResponse>(...args).pipe(
      // delay(3000),
     /* map((httpResponse: HolosenApiResponse) => {
        if (httpResponse.ok) {
          return {
            ok: true,
            payload: httpResponse.payload
          };
        } else {
          return {
            ok: false,
            message: httpResponse.message
          };
        }
      })*/
    );
  };


  get = (url: string, options?: any): Observable<IHolosenHttpServiceResponse> => {
    return this.query(
      {
        method: HTTP_METHODS.GET,
        url,
        options
      }
    );
  };

  post = (url: string, body: any, options?: any): Observable<IHolosenHttpServiceResponse> => {
    return this.query(
      {
        method: HTTP_METHODS.POST,
        url,
        body,
        options
      }
    );
  };

  put = (url: string, body: any, options?: any): Observable<IHolosenHttpServiceResponse> => {
    return this.query(
      {
        method: HTTP_METHODS.PUT,
        url,
        body,
        options
      }
    );
  };

  delete = (url: string, body: any, options?: any): Observable<IHolosenHttpServiceResponse> => {
    return this.query(
      {
        method: HTTP_METHODS.DELETE,
        url,
        body,
        options
      }
    );
  };
};
