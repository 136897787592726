import {Injectable} from '@angular/core';
import {EntityState} from '../entity-state';
import {holosenEntityMetadataStorage} from '../metadata/holosen-entity-metadata-storage';
import {combineLatest, Observable, of} from 'rxjs';
import {concatMap, map, switchMap, take, tap} from 'rxjs/operators';
import {BaseEntity} from '../entities/base/base-entity';
import {classToPlain, plainToClass} from 'class-transformer';
import {normalize, schema} from 'normalizr';
import {filterByID} from '../helpers/helpers';
import {
  HolosenStateManagementEntityUpsertOptions,
  HolosenStateManagementPaginateOptions,
  HolosenStateManagementSelectOptions,
  HolosenStateManagementUpsertOptions
} from '../interfaces/holosen-state-management-options';
import {HolosenEntityPaginationResponse} from '../interfaces/holosen-entity-pagination-response';


interface StoreModel {
  [key: string]: EntityState<any>;
};


@Injectable({
  providedIn: 'root'
})
export class HolosenStateManagementService {

  private readonly _store: StoreModel = {} as StoreModel;
  private devTools: any;


  private initDevTools = () => {
    const reduxDevToolsKey = '__REDUX_DEVTOOLS_EXTENSION__';
    const hasDevTools = typeof window !== 'undefined' && window.hasOwnProperty(reduxDevToolsKey);

    if (!hasDevTools) {
      console.error('The browser does not have a Redux DevTools extension.');
      return;
    }
    // console.log('Redux DevTools extension is initializing');
    this.devTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__.connect();
    this.devTools.init();
    // console.log('Redux DevTools extension initial state is set');
  };


  private initStore = () => {
    holosenEntityMetadataStorage.getMetadataList().forEach((model, key) => {
      this._store[model.options.storeName] = new EntityState<any>(model.target.prototype.constructor, this.checkDeletionPermission);
    });
    // console.log(`[this._store] `, this._store);
    this.updateDevTools('STATES CREATED');
  };


  public initEntitySchemas = () => {
    holosenEntityMetadataStorage.initEntitySchemas();
  };

  private updateDevTools = (action: string) => {
    if (this.devTools) {
      const loggedWarehouse = {};
      for (const [storeName, state] of Object.entries(this._store)) {
        loggedWarehouse[storeName] = state.getDevtoolsValue();
      }
      this.devTools.send(action, loggedWarehouse);
    }
  };

  constructor() {
    this.initDevTools();
    this.initStore();
  }


  private getStateByStoreName = (storeName: string) => {
    return this._store[storeName];
  };

  public upsert = <T extends BaseEntity>(
    classType: new() => T,
    payload: T | T[] | any,
    options: HolosenStateManagementUpsertOptions = {
      onlySelf: false,
      paginated: false
    }
  ): Observable<boolean> => {


    const parentStoreName = holosenEntityMetadataStorage.getStoreName(classType.name);
    const normalizedPayload = this.normalize(classType, options.paginated ? (payload as any).data : payload as (T | T[]));
    const upsertResult = [];

    Object.entries(normalizedPayload.entities).forEach(([entityStoreName, entities]) => {
      const willBePaginated = options.paginated && entityStoreName === parentStoreName;
      let upsertOptions: HolosenStateManagementEntityUpsertOptions = {paginated: willBePaginated};
      if (willBePaginated) {
        const {data, ...paginationResult} = payload;
        upsertOptions = {
          ...upsertOptions,
          paginationResult: {
            ...paginationResult,
            orderBy: data.map(e => e.id)
          }
        };
      }
      upsertResult.push(
        this.getStateByStoreName(entityStoreName).upsert(Object.values(entities), upsertOptions).pipe(
          tap(_ => {
            this.updateDevTools(`[${entityStoreName}] Upserted`);
          }),
          map(_ => true)
        )
      );
    });

    if (upsertResult.length === 0) {
      upsertResult.push(of([]));
    }

    return combineLatest(upsertResult).pipe(
      // tap(_ => console.log(`[UPSERTED] ${classType.name}`)),
      map(_ => true)
    );
  };


  public select = <T extends BaseEntity>(
    classType: new() => T,
    options: HolosenStateManagementSelectOptions = {
      paginated: false
    }
  ): Observable<Array<any>> => {
    const storeName = holosenEntityMetadataStorage.getStoreName(classType.name);
    return this.getStateByStoreName(storeName).select({
      filterFunction: options.filterFunction,
      paginated: options.paginated
    }).pipe(
      switchMap((selectedEntities) => {

        const entityMetadata = holosenEntityMetadataStorage.getMetadataByName(classType.name);
        const withChildren = options.with ? options.with : Array.from(entityMetadata.children.keys());
        const childSelectionObservables = [];

        selectedEntities.forEach((entity) => {
          withChildren.forEach((childPropertyKey) => {
            const childClassFunction = holosenEntityMetadataStorage.getChildClassFunctionByPropertyKey(classType.name, childPropertyKey);
            if (!childClassFunction) {
              return;
            }


            const childSelectionObservable = this.select(childClassFunction as (new() => T), {
              filterFunction: filterByID(entity[childPropertyKey]),
              paginated: false
            }).pipe(
              map(entities => {
                if (!entityMetadata.children.get(childPropertyKey).isArray) {
                  return entities[0];
                }

                return entities;
              }),
              tap(selection => {
                entity[childPropertyKey] = selection;
              })
            );
            childSelectionObservables.push(childSelectionObservable);
          });
        });

        return combineLatest(
          [
            of(selectedEntities),
            ...childSelectionObservables
          ]
        );
      }),
      map(([selectedEntities, ...childSelection]) => {
        return plainToClass(classType.prototype.constructor, selectedEntities, {groups: ['fromStateManager']});
      })
    );
  };


  public paginate = <T extends BaseEntity>(
    classType: new() => T,
    options: HolosenStateManagementPaginateOptions = {
      append: false
    }
  ): Observable<HolosenEntityPaginationResponse<T>> => {

    return this.select(classType, {paginated: true}).pipe(
      map(entities => {
        const pagination = this.getStateByStoreName(holosenEntityMetadataStorage.getStoreName(classType.name)).pagination.result;
        console.log(`[pagination] `, pagination);
        entities.sort((a, b) => {
          return pagination.orderBy.indexOf(a.id) - pagination.orderBy.indexOf(b.id);
        });

        return {entities, pagination};
      })
    ); // , {filterFunction: filterByID([1, 3])}
  };

  public delete = <T extends BaseEntity>(
    classType: new() => T,
    payload: number | number[]
  ): Observable<any> => {
    const storeName = holosenEntityMetadataStorage.getStoreName(classType.name);
    const filterFunction = filterByID(Array.isArray(payload) ? payload : [payload]);
    return this.select(classType, {filterFunction}).pipe(
      take(1),
      concatMap(selectedEntities => {

        const deletionObservables = [];
        selectedEntities.forEach(entity => {
          const entityMetadata = holosenEntityMetadataStorage.getMetadataByName(classType.name);
          const childProperties = Array.from(entityMetadata.children.keys());


          const obs = this.getStateByStoreName(storeName).delete(entity.id).pipe(
            concatMap(_ => {

              const childObservables = [];
              childProperties.forEach(childPropertyKey => {
                const childClassFunction = holosenEntityMetadataStorage.getChildClassFunctionByPropertyKey(classType.name, childPropertyKey);
                const childrenIds = Array.isArray(entity[childPropertyKey]) ? entity[childPropertyKey].map(ent => ent.id) : entity[childPropertyKey].id;
                childObservables.push(this.delete(childClassFunction as (new() => T), childrenIds));
              });

              if (childObservables.length === 0) {
                childObservables.push(of(null));
              }
              return combineLatest(childObservables);
            })
          );

          deletionObservables.push(obs);

        });

        if (deletionObservables.length === 0) {
          deletionObservables.push(of(null));
        }

        return combineLatest(deletionObservables);


      }),
      tap(deletionResult => {
        if (Array.isArray(deletionResult) && deletionResult[0] !== null) {
          this.updateDevTools(`[${storeName}] Deleted`);
        }
      })
    );
  };


  /************************************************
   * UTILITY METHODS
   ************************************/


  private normalize = <T extends BaseEntity>(classType: new() => T, payload: T | Array<T>) => {
    const entitySchema = holosenEntityMetadataStorage.getMetadataByName(classType.name).schema;
    return normalize(classToPlain(payload, {groups: ['toStateManager']}), Array.isArray(payload) ? new schema.Array(entitySchema) : entitySchema);
    // return normalize(payload, Array.isArray(payload) ? new schema.Array(entitySchema) : entitySchema);
  };

  private checkDeletionPermission = (entityName: string, ids: number[]): number[] => {
    const parents = holosenEntityMetadataStorage.getMetadataByName(entityName).parents;
    let idsToDelete = [...ids];
    parents.forEach((properties, parentEntityName) => {
      const parentState = this.getStateByStoreName(holosenEntityMetadataStorage.getMetadataByName(parentEntityName).options.storeName);
      properties.forEach((propertyKey) => {
        idsToDelete = idsToDelete.filter(id => !parentState.hasChildren(propertyKey, id));
      });
    });
    return idsToDelete;
  };


}
