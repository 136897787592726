import {Exclude, Expose, Transform, Type} from 'class-transformer';
import {IsNotEmpty} from 'class-validator';
import {Unit} from '../unit/unit.entity';
import {BaseEntity} from '../base/base-entity';


@Exclude()
export abstract class StockItem extends BaseEntity {

  @Expose()
  @IsNotEmpty()
  sku: string;

  @Expose()
  @IsNotEmpty()
  name: string;

  @Expose()
  description: string;

  @Expose()
  @IsNotEmpty()
  @Type(() => Unit)
  @Transform(unit => unit.id, {toPlainOnly: true, groups: ['toAPI']})
  unit: Unit;

  @Expose()
  @IsNotEmpty()
  @Type(() => Number)
  currentStockLevel: number = 0;
}










