import {Injector, NgModule} from '@angular/core';

export let InjectorInstance: Injector;

@NgModule({
  declarations: [],
  imports: []
})
export class HolosenInjectorModule {

  constructor(private injector: Injector) {
    InjectorInstance = this.injector;
  }
}
