import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {BaseEntity} from '../../../state-management/entities/base/base-entity';

@Component({
    selector: 'app-planbu-autocomplete-selection',
    templateUrl: './planbu-autocomplete-selection.component.html',
    styleUrls: ['./planbu-autocomplete-selection.component.less']
})
export class PlanbuAutocompleteSelectionComponent implements OnInit {

    @Input()
    entity: any;

    @Input()
    autocompleteDataSource;

    @Input()
    autocompleteItemTemplate: TemplateRef<any>;

    @Input()
    selectedItemTemplate: TemplateRef<any>;

    @Input()
    nullable = false;

    @Output()
    entitySelected: EventEmitter<any> = new EventEmitter<any>();


    isChanging = false;

    constructor() {
    }

    ngOnInit(): void {
        if (this.selectedItemTemplate === null) {
            console.error('selectedItemTemplate cannot be empty in PlanbuAutocompleteSelectionComponent');
        }

        if (this.autocompleteItemTemplate === null) {
            console.error('autocompleteItemTemplate cannot be empty in PlanbuAutocompleteSelectionComponent');
        }
    }

    selectEntity = (entity: any) => {
        this.entitySelected.emit(entity);
        this.toggleIsChanging(false)
    };

    toggleIsChanging = (val: boolean) => {
        this.isChanging = val;
    };

}
