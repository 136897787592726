import {holosenEntityMetadataStorage} from '../metadata/holosen-entity-metadata-storage';
import {HolosenEntityChildMetadata} from '../metadata/holosen-entity-child-metadata';

export function HolosenEntityChild(propertyType: () => Function, options?: any): PropertyDecorator {
  return (target: Object, propertyKey: string) => {

    const isArray = Reflect.getMetadata('design:type', target, propertyKey) === Array;
    const propertyClassName = propertyType().prototype.constructor.name;
    const metadata = new HolosenEntityChildMetadata(isArray, propertyClassName, propertyType(), options);
    holosenEntityMetadataStorage.addChildToEntity(target, propertyKey, metadata);
  };
}
