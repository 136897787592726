import {Exclude, Expose, Type} from 'class-transformer';
import {BaseEntity} from '../base/base-entity';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {HolosenEntityChild} from '../../decorators/HolosenEntityChild';
import {Contact} from '../contact/contact.entity';
import {Address, AddressType} from '../address/address.entity';
import {IsEmail, IsNotEmpty, ValidateIf, ValidateNested} from 'class-validator';


@Exclude()
@HolosenEntityModel({
  apiPrefix: 'business-partners/business-partners',
  storeName: 'businessPartners'
})
export class BusinessPartner extends BaseEntity {

  @Expose()
  @IsNotEmpty()
  title: string;

  @Expose()
  @IsNotEmpty()
  code: string;


  @Expose()
  phone: string;

  @Expose()
  @ValidateIf(o => o.email)
  @IsEmail({}, {message: 'Invalid e-mail address'})
  email: string;


  @Expose()
  additionalNotes: string;


  @Expose()
  @HolosenEntityChild(() => Contact)
  @Type(() => Contact)
  contacts: Array<Contact> = [];

  @Expose()
  @HolosenEntityChild(() => Address)
  @Type(() => Address)
  @ValidateNested()
  addresses: Array<Address> = [];


  @Exclude()
  get billingAddresses(): Address[] {
    return this.addresses.filter(adr => adr.type === AddressType.BILLING);
  };

  @Exclude()
  get shippingAddresses(): Address[] {
    return this.addresses.filter(adr => adr.type === AddressType.SHIPPING);
  };


}

