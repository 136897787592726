import {NgModule} from '@angular/core';
import {SharedImportsModule} from '../../shared-imports.module';
import {PublicLayoutComponent} from './public-layout.component';


@NgModule({
  imports: [SharedImportsModule],
  declarations: [PublicLayoutComponent],
  exports: [PublicLayoutComponent]
})
export class PublicLayoutModule {
}
