import {Exclude, Expose, Transform, Type} from 'class-transformer';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {IsDate, IsNotEmpty} from 'class-validator';
import {User} from '../user/user.entity';
import {HolosenEntityChild} from '../../decorators/HolosenEntityChild';
import {BaseEntity} from '../base/base-entity';
import * as moment from 'moment';


export enum ACTYIVITY_SOURCE_TYPE {
  CPA = 1,
  TRAINING = 2,
  CHANGE = 3,
  KPI = 4,
  MEETING = 5,
  RFA = 6,
  QUALITY_OBJECTIVE = 7
}


export enum ActivityTypesEnum {
  CORRECTIVE = 1,
  PREVENTIVE = 2,
  // CHANGE_REQUEST = 3,
  TRAINING = 4,
  DOCUMENTED_INFO = 5,
  PROCESS = 6,
  WORK_CENTER = 7,
  TOOL = 8,
  SUPPLIER = 9,
  TRAINING_CHANGE = 10,
  KPI = 11,
  MEETING = 12,
  RFA = 13
}

export const ACTIVITY_TYPES = {
  [ACTYIVITY_SOURCE_TYPE.CPA]: {
    CORRECTIVE: {
      name: 'Düzeltici',
      value: ActivityTypesEnum.CORRECTIVE
    },
    PREVENTIVE: {
      name: 'Önleyici',
      value: ActivityTypesEnum.PREVENTIVE
    },
    //   CHANGE_REQUEST: {
    //     name: 'Değişiklik Talebi',
    //     value: ActivityTypesEnum.CHANGE_REQUEST
    //   },
  },
  [ACTYIVITY_SOURCE_TYPE.TRAINING]: {
    TRAINING: {
      name: 'Eğitim',
      value: ActivityTypesEnum.TRAINING
    }
  },
  [ACTYIVITY_SOURCE_TYPE.CHANGE]: {
    DOCUMENTED_INFO: {
      name: 'Dokümante Edilmiş Bilgi',
      value: ActivityTypesEnum.DOCUMENTED_INFO
    },
    PROCESS: {
      name: 'Süreç',
      value: ActivityTypesEnum.PROCESS
    },
    WORK_CENTER: {
      name: 'Makine',
      value: ActivityTypesEnum.WORK_CENTER
    },
    TOOL: {
      name: 'Araç & Gereç',
      value: ActivityTypesEnum.TOOL
    },
    TRAINING: {
      name: 'Eğitim',
      value: ActivityTypesEnum.TRAINING_CHANGE
    },
    SUPPLIER: {
      name: 'Tedarikçi Yönetimi',
      value: ActivityTypesEnum.SUPPLIER
    }
  },
  [ACTYIVITY_SOURCE_TYPE.KPI]: {
    KPI: {
      name: 'KPI',
      value: ActivityTypesEnum.KPI
    }
  },
  [ACTYIVITY_SOURCE_TYPE.MEETING]: {
    MEETING: {
      name: 'KPI',
      value: ActivityTypesEnum.MEETING
    }
  },
  [ACTYIVITY_SOURCE_TYPE.RFA]: {
    RFA: {
      name: 'RFA',
      value: ActivityTypesEnum.RFA
    }
  },
}


export const ACTYIVITY_STATUS = {
  1: {
    value: 1,
    name: 'NEW',
    color: '#5F3EFF'
  },
  2: {
    value: 2,
    name: 'DOING',
    color: '#D6783E'
  },
  3: {
    value: 3,
    name: 'REVIEW',
    color: '#16BAC5'
  },
  4: {
    value: 4,
    name: 'DONE',
    color: '#E0BC08'
  },
  5: {
    value: 5,
    name: 'CANCELLED',
    color: '#e02331'
  },
}

@Exclude()
@HolosenEntityModel({
  apiPrefix: 'planning/activities',
  storeName: 'activities'
})
export class Activity extends BaseEntity {

  @Expose()
  @IsNotEmpty()
  @Type(() => User)
  @Transform((trainee) => trainee.id, {groups: ['toAPI']})
  @HolosenEntityChild(() => User)
  responsible: User;

  @Expose({groups: ['fromAPI', 'toStateManager', 'fromStateManager']})
  source: { contentType: string, code: string, id: number };

  @Expose()
  @IsNotEmpty()
  name: string;

  @Expose()
  @IsNotEmpty()
  description: string;

  @Expose()
  @IsNotEmpty()
  type: number;

  @Expose()
  @IsNotEmpty()
  status: number = ACTYIVITY_STATUS[1].value;

  @Expose()
  @IsNotEmpty()
  @IsDate()
  @Type(() => Date)
  @Transform(value => moment(value, 'YYYY-MM-DD HH:mm:ss').toDate(), {toClassOnly: true})
  @Transform((value: Date) => moment(value).format('YYYY-MM-DD HH:mm:ss'), {toPlainOnly: true})
  deadline: Date;


  @Expose()
  @Type(() => Date)
  @Transform(value => value ? moment(value, 'YYYY-MM-DD HH:mm:ss').toDate() : null, {toClassOnly: true})
  @Transform((value: Date) => value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : null, {toPlainOnly: true})
  executedAt: Date;

}

