<ng-container>
  <nz-layout class="app-layout">

    <nz-header [ngClass]="{collapsed: isCollapsed}">
      <app-header [isCollapsed]="isCollapsed"
                  (onToggleSidebarCollapse)="onToggleSidebarCollapse(isCollapsed)"></app-header>
    </nz-header>
    <nz-layout class="app-content-layout">
      <nz-sider nzTheme="light"
                [ngClass]="{'collapsed': isCollapsed}"
                nzCollapsible
                nzWidth="256px"
                nzBreakpoint="md"
                [nzCollapsible]="true"
                [nzCollapsed]="isCollapsed"
                [nzTrigger]="null"
      >
        <app-sidebar [isCollapsed]="isCollapsed"></app-sidebar>
      </nz-sider>

      <nz-content >
        <div class="app-content" [ngClass]="{'collapsed': isCollapsed}">
          <router-outlet></router-outlet>
        </div>
      </nz-content>
    </nz-layout>
  </nz-layout>
</ng-container>
