import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({selector: '[planbuVar]'})
export class PlanbuLocalVariableDirective implements OnInit {
  @Input()
  set planbuVar(context: any) {
    this.context.$implicit = this.context.planbuVar = context;
    this.updateView();
  }

  context: any = {};

  constructor(private vcRef: ViewContainerRef, private templateRef: TemplateRef<any>) {
  }

  ngOnInit(): void {
    this.updateView();
  }


  updateView() {
    this.vcRef.clear();
    this.vcRef.createEmbeddedView(this.templateRef, this.context);
  }
}
