import {AfterViewInit, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HolosenLocalStorageService} from './core/services/holosen-local-storage.service';
import {AppConfig} from './app.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {


  constructor() {

  }

  ngAfterViewInit(): void {
  }


}
