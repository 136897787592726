import {Exclude, Expose, Type} from 'class-transformer';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {StockItem} from '../stock-item/stock-item.entity';
import {ProductRequirement, ProductRequirementType, RawMaterialRequirement, WIPRequirement} from '../product-requirement/product-requirement.entity';
import {BomOperation} from '../bom-operation/bom-operation.entity';
import {HolosenEntityChild} from '../../decorators/HolosenEntityChild';
import {ArrayNotEmpty} from 'class-validator';


@Exclude()
@HolosenEntityModel({
  apiPrefix: 'inventory/products',
  storeName: 'products'
})
export class Product extends StockItem {

  @Expose({groups: ['fromAPI', 'toStateManager', 'fromStateManager']})
  @Type(() => BomOperation)
  @HolosenEntityChild(() => BomOperation)
  @ArrayNotEmpty({
    message: 'A Product must have at least 1 operation'
  })
  bomOperations: Array<BomOperation> = [];

  @Expose({groups: ['fromAPI', 'toStateManager', 'fromStateManager']})
  @HolosenEntityChild(() => RawMaterialRequirement)
  @Type(() => ProductRequirement, {
    discriminator: {
      property: 'contentType',
      subTypes: [
        {value: RawMaterialRequirement, name: ProductRequirementType[ProductRequirementType.RAW_MATERIAL]},
        {value: WIPRequirement, name: ProductRequirementType[ProductRequirementType.WIP]},
      ]
    },
    keepDiscriminatorProperty: true
  })
  requirements: Array<RawMaterialRequirement | WIPRequirement> = [];

}

