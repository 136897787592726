import {Exclude, Expose, Type} from 'class-transformer';
import {BaseEntity} from '../base/base-entity';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {IsNotEmpty} from 'class-validator';
import {Currency} from '../currency/currency.entity';
import {Tax} from '../tax/tax.entity';
import {Product} from '../product/product.entity';


export enum DiscountType {
  PERCENT = 1,
  FIXED = 2
}


@Exclude()
@HolosenEntityModel({
  apiPrefix: 'sales/sales-order-items',
  storeName: 'salesOrderItems'
})
export class SalesOrderItem extends BaseEntity {

  @Expose()
  @Type(() => Product)
  product;

  @Expose()
  quantity: number = 1;

  @Expose()
  discountAmount: number = 0;

  @Expose()
  discountType: number = DiscountType.PERCENT;

  @Expose()
  @IsNotEmpty()
  price: number;

  @Expose()
  @Type(() => Currency)
  currency: Currency;

  @Expose()
  @Type(() => Tax)
  tax: Tax;


}

