import {Exclude} from 'class-transformer';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {StockItem} from '../stock-item/stock-item.entity';


@Exclude()
@HolosenEntityModel({
  apiPrefix: 'inventory/scrap-materials',
  storeName: 'scrapMaterials'
})
export class ScrapMaterial extends StockItem {

}

