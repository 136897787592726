import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'statusProps'
})
export class StatusPropsPipe implements PipeTransform {


  transform(status: number): unknown {
    const props = {
      bgColor: '#cccccc',
      displayName: 'N/A'
    }


    switch (status) {
      case 1: {
        props.bgColor = '#5F3EFF';
        props.displayName = 'NEW';
        break;
      }
      case 2: {
        props.bgColor = '#D6783E';
        props.displayName = 'DOING';
        break;
      }
      case 3: {
        props.bgColor = '#16BAC5';
        props.displayName = 'REVIEW';
        break;
      }
      case 4: {
        props.bgColor = '#E0BC08';
        props.displayName = 'DONE';
        break;
      }
    }

    return props;

  }


}
