import {holosenEntityMetadataStorage} from '../metadata/holosen-entity-metadata-storage';
import {HolosenEntityMetadata} from '../metadata/holosen-entity-metadata';

export interface HolosenEntityModelOption {
  storeName: string;
  apiPrefix: string;
}

export function HolosenEntityModel(options: HolosenEntityModelOption): ClassDecorator {
  return (target: Function) => {
    holosenEntityMetadataStorage.addEntity(new HolosenEntityMetadata({target, options}));
  };
}
