import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {HolosenAuthenticationService} from '../../../../core/services/holosen-authentication.service';
import {Subject} from 'rxjs';
import {User} from '../../../../state-management/entities/user/user.entity';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AppConfig} from '../../../../app.config';
import {HolosenLocalStorageService} from '../../../../core/services/holosen-local-storage.service';
import {NzI18nService} from 'ng-zorro-antd';
import {default as en} from '../../../../core/i18n/languages/en';
import {default as tr} from '../../../../core/i18n/languages/tr';
import {PlanbuTranslationService} from '../../../../core/i18n/planbu-translation.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input()
  isCollapsed = false;

  @Output()
  onToggleSidebarCollapse = new EventEmitter<void>();

  onDestroy$ = new Subject();


  user: User;

  currentLanguage = this.planbuTranslationService.currentLanguage$;

  constructor(private authService: HolosenAuthenticationService,
              private router: Router,
              private planbuTranslationService: PlanbuTranslationService
  ) {

  }

  ngOnInit() {

    this.authService.getCurrentUser().subscribe((user) => {
      this.user = user;
    });

  }

  changeLanguage = (language: string) => {
    this.planbuTranslationService.translate(language);
  };

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

  toggleSidebarCollapse = () => {
    this.onToggleSidebarCollapse.emit();
  };

  logout = () => {
    this.authService.logout().subscribe(
      success => {
        this.router.navigate(['/auth/login'], {queryParams: {returnUrl: this.router.url}});
      },
      error => {
        console.log(`[error] `, error);
      }
    );
  };

}
