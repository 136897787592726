import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PublicLayoutComponent} from '../layout/public/public-layout.component';


const publicRoutes: Routes = [
  {
    path: '', component: PublicLayoutComponent, children: [
      {path: '', pathMatch: 'full', redirectTo: '/dashboard'},
      {path: 'auth', loadChildren: () => import('../modules/auth/auth.module').then(m => m.AuthModule)}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(publicRoutes)],
  exports: [RouterModule]
})
export class PublicRoutingModule {
}
