import {HolosenEntityChildMetadata} from './holosen-entity-child-metadata';
import {HolosenEntityModelOption} from '../decorators/HolosenEntityModel';


export interface HolosenEntityMetadataOptions {
  target?: Function;
  children?: Map<string, HolosenEntityChildMetadata>;
  options?: HolosenEntityModelOption;
}

export class HolosenEntityMetadata {

  public target: Function = null;
  public children: Map<string, HolosenEntityChildMetadata> = new Map();
  public parents: Map<string, string[]> = new Map();
  public options?: HolosenEntityModelOption;
  public schema = null;


  constructor(args?: HolosenEntityMetadataOptions) {
    if (args) {
      if (args.target) {
        this.target = args.target;
      }
      if (args.children) {
        this.children = args.children;
      }
      if (args.options) {
        this.options = args.options;
      }
    }
  }
}
