import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {HolosenAuthenticationService} from '../services/holosen-authentication.service';
import {tap} from 'rxjs/operators';
import {NzNotificationService} from 'ng-zorro-antd';

@Injectable({
  providedIn: 'root'
})
export class Gatekeeper implements CanActivate, CanActivateChild {

  constructor(private authenticationService: HolosenAuthenticationService,
              private router: Router,
              private notificationService: NzNotificationService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.authenticationService.isLoggedIn().pipe(
      tap((isLoggedIn) => {
        if (!isLoggedIn) {
          this.notificationService.create(
            'error',
            'Session Timed Out',
            'You need to login in order to access modules'
          );
          this.router.navigate(['/auth/login'], {queryParams: {returnUrl: state.url}});
        }
      })
    );
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.canActivate(next, state).pipe();
  }

}
