<li nz-menu-item *ngIf="menuItem && (!menuItem.children || menuItem.children.length === 0)"
    [nzSelected]="isActive"
    [routerLink]="[menuItem.url]"
    routerLinkActive>
  <i nz-icon nzType="dashboard"></i>
  <span>{{menuItem.text}}</span>
</li>
<li nz-submenu *ngIf="menuItem && (menuItem.children && menuItem.children.length > 0)"
    [nzOpen]="isActive"
    [nzTitle]="menuItem.text"
    nzIcon="form">
  <ul>
    <li nz-menu-group><span title><i nz-icon nzType="appstore"></i><span>SubTitle</span></span></li>
    <li nz-menu-item [nzSelected]="isActive" *ngFor="let submenuItem of menuItem.children" >
      <a [routerLink]="[submenuItem.url]"
         routerLinkActive>
        {{submenuItem.text}}
      </a>
    </li>
  </ul>
</li>


<!--<li *ngIf="!menuItem.children" [ngClass]="{'active': childlessRouterLink.isActive}">-->
<!--  <a-->
<!--    aria-expanded="false"-->
<!--    [routerLink]="[menuItem.url]"-->
<!--    [routerLinkActive]="['active']"-->
<!--    #childlessRouterLink="routerLinkActive"-->
<!--  >-->
<!--    <i [ngClass]="menuItem.icon"></i>-->
<!--    <span class="hide-menu">-->
<!--        {{menuItem.text}}-->
<!--    </span>-->
<!--  </a>-->
<!--</li>-->

<!--<ng-container *ngIf="menuItem.children">-->
<!--  <li class="with-children" [ngClass]="{'active': isActive}">-->
<!--    <a class="has-arrow"-->
<!--       href="#"-->
<!--       [attr.aria-expanded]="isActive">-->
<!--      <i class="fa-fw" [ngClass]="menuItem.icon"></i>-->
<!--      <span class="hide-menu">-->
<!--        {{menuItem.text}}-->
<!--      </span>-->
<!--    </a>-->
<!--    <ul [attr.aria-expanded]="isActive"-->
<!--        class="collapse submenu"-->
<!--        [ngClass]="{'in': isActive }">-->
<!--      <li *ngFor="let subMenuItem of menuItem.children">-->
<!--        <a [routerLink]="[subMenuItem.url]"-->
<!--           [routerLinkActive]="['active']"-->
<!--           [routerLinkActiveOptions]="{exact: true}">-->
<!--          {{subMenuItem.text}}-->
<!--        </a>-->
<!--      </li>-->
<!--    </ul>-->
<!--  </li>-->
<!--</ng-container>-->
