import {Injectable} from '@angular/core';
import {default as en} from './languages/en';
import {default as tr} from './languages/tr';
import {AppConfig} from '../../app.config';
import {TranslateService} from '@ngx-translate/core';
import {NzI18nService} from 'ng-zorro-antd';
import {HolosenLocalStorageService} from '../services/holosen-local-storage.service';
import {BehaviorSubject} from 'rxjs';
import {registerLocaleData} from '@angular/common';
import tr_TR from '@angular/common/locales/tr';
import en_US from '@angular/common/locales/en';
@Injectable({
  providedIn: 'root'
})
export class PlanbuTranslationService {

  currentLanguageSubject = new BehaviorSubject('tr');
  currentLanguage$ = this.currentLanguageSubject.asObservable();

  constructor(private translateService: TranslateService,
              private ngZorroTranslateService: NzI18nService,
              private storageService: HolosenLocalStorageService) {
    registerLocaleData(tr_TR);
    registerLocaleData(en_US);
  }

  translate = (language: string) => {
    this.translateService.use(language);

    switch (language) {
      case 'en': {
        this.ngZorroTranslateService.setLocale(en);
        break;
      }
      case 'tr': {
        this.ngZorroTranslateService.setLocale(tr);
        break;
      }
      default: {
        this.ngZorroTranslateService.setLocale(en);
        break;
      }
    }

    this.currentLanguageSubject.next(language);
    this.storageService.set(AppConfig.STORAGE_KEYS.LANGUAGE_KEY, language, true);

  };
}
