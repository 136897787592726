export const AppConfig = Object.freeze(
  {
    URL: {
      BASE: 'https://api.planning.bo-tek.com.tr/',
      API: 'https://api.planning.bo-tek.com.tr/api/'
    },
    DEFAULT_RETURN_URL: '/dashboard',
    STORAGE_KEYS: {
      TOKEN_STORAGE_KEY: 'token-data',
      LANGUAGE_KEY: 'ui-lang',
    }
  }
);
