import {Exclude, Expose, Transform, Type} from 'class-transformer';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {BasePlannable} from '../base-plannable/base-plannable.entity';
import {IsNotEmpty} from 'class-validator';
import {User} from '../user/user.entity';
import {Media} from '../media/media.entity';
import {HolosenEntityChild} from '../../decorators/HolosenEntityChild';
import {Activity} from '../activity/activity.entity';

export const TRAINING_SOURCE_TYPES = {
  CHANGE_REQUEST: {
    name: 'Değişiklik Talebi',
    value: 1
  },
  RISK_OPPORTUNITY: {
    name: 'Risk & Fırsat Analizi',
    value: 2
  },
  CPA: {
    name: 'DÖF',
    value: 3
  },
  EMPLOYEE_REQUEST: {
    name: 'Personel Talebi',
    value: 4
  },
  KPI: {
    name: 'KPI',
    value: 5
  }
}


@Exclude()
@HolosenEntityModel({
  apiPrefix: 'planning/trainings',
  storeName: 'trainings'
})
export class Training extends BasePlannable {

  @Expose()
  @Type(() => Activity)
  @HolosenEntityChild(() => Activity)
  activities: Array<Activity> = [];

  @Expose()
  @IsNotEmpty()
  @Type(() => User)
  @Transform((trainee) => trainee.id, {groups: ['toAPI']})
  @HolosenEntityChild(() => User)
  responsible: User;

  @Expose()
  @Type(() => Media)
  @HolosenEntityChild(() => Media)
  attachments: Array<Media> = [];


  @Expose()
  @Type(() => User)
  @HolosenEntityChild(() => User)
  @Transform((trainee) => trainee.map(emp => emp.id), {groups: ['toAPI']})
  trainees: User[] = [];

  @Expose()
  @IsNotEmpty()
  aimAndGoal: string;

  @Expose()
  @IsNotEmpty()
  trainingMethod: string;

  @Expose()
  immediateRanks: { [key: number]: number } = {};

  @Expose()
  reviewRanks: { [key: number]: number } = {};

  @Expose()
  @IsNotEmpty()
  totalDuration: number;

  @Expose()
  @IsNotEmpty()
  source: number;

  @Expose()
  sourceName: string;


}

