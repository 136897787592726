import {Exclude, Expose, Transform, Type} from 'class-transformer';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {BasePlannable} from '../base-plannable/base-plannable.entity';
import {IsNotEmpty} from 'class-validator';
import {User} from '../user/user.entity';
import {Media} from '../media/media.entity';
import {HolosenEntityChild} from '../../decorators/HolosenEntityChild';
import {Activity} from '../activity/activity.entity';
import {Kpi} from '../kpi/kpi.entity';


@Exclude()
@HolosenEntityModel({
  apiPrefix: 'planning/quality-objectives',
  storeName: 'qualityObjectives'
})
export class QualityObjective extends BasePlannable {

  @Expose()
  @Type(() => Activity)
  @HolosenEntityChild(() => Activity)
  activities: Array<Activity> = [];

  @Expose()
  @IsNotEmpty()
  @Type(() => User)
  @Transform((trainee) => trainee.id, {groups: ['toAPI']})
  @HolosenEntityChild(() => User)
  responsible: User;

  @Expose()
  @Type(() => Media)
  @HolosenEntityChild(() => Media)
  attachments: Array<Media> = [];


  @Expose()
  @Type(() => Kpi)
  @HolosenEntityChild(() => Kpi)
  @Transform((kpis) => kpis.map(kpi => kpi.id), {groups: ['toAPI']})
  kpis: Kpi[] = [];


  @Expose()
  @IsNotEmpty()
  objective: number;


}

