import {Exclude, Expose, Transform} from 'class-transformer';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {BaseEntity} from '../base/base-entity';
import {StockItem} from '../stock-item/stock-item.entity';
import {RawMaterial} from '../raw-material/raw-material.entity';
import {WorkInProcess} from '../work-in-process/work-in-process.entity';


export enum ProductRequirementType {
  RAW_MATERIAL = 1,
  WIP = 2
}

@Exclude()
@HolosenEntityModel({
  apiPrefix: 'stock/product-requirements',
  storeName: 'productRequirements'
})
export class ProductRequirement<T extends StockItem> extends BaseEntity {

  @Expose()
  @Transform(ro => ro.id, {groups: ['toAPI']})
  requiredObject: T;

  @Expose()
  contentType: string;

  @Expose()
  quantity: number = 0;

}

@HolosenEntityModel({
  apiPrefix: 'stock/product-requirements',
  storeName: 'productRequirements'
})
export class RawMaterialRequirement extends ProductRequirement<RawMaterial>{}

@HolosenEntityModel({
  apiPrefix: 'stock/product-requirements',
  storeName: 'productRequirements'
})
export class WIPRequirement extends ProductRequirement<WorkInProcess>{}

