import {Exclude, Expose, Type} from 'class-transformer';
import {Media} from '../media/media.entity';
import {WorkCenter} from '../work-center/work-center.entity';
import {IAttachable} from '../../interfaces/holosen-attachable-entity';
import {BaseEntity} from '../base/base-entity';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {HolosenEntityChild} from '../../decorators/HolosenEntityChild';
import {IsNotEmpty} from 'class-validator';

export const TAG_TYPES: { [key: string]: { value: number, displayName: string } } = {
  PRODUCT: {
    value: 1,
    displayName: 'Product'
  },
  BUSINESS_PARTNER: {
    value: 2,
    displayName: 'Business Partner'
  },
  WORK_CENTER: {
    value: 3,
    displayName: 'Work Center'
  },
  ATTACHMENT: {
    value: 4,
    displayName: 'Attachment'
  },
  SALES_QUOTE: {
    value: 5,
    displayName: 'Sales Quote'
  },
  RFQ: {
    value: 6,
    displayName: 'RFQ'
  },
  PURCHASE_ORDER: {
    value: 7,
    displayName: 'Purchase Order'
  },
  SALES_ORDER: {
    value: 8,
    displayName: 'Sales Order'
  },
  MANUFACTURING_ORDER: {
    value: 9,
    displayName: 'Manufacturing Order'
  },
  RAW_MATERIAL: {
    value: 10,
    displayName: 'Raw Material'
  },
  CONTACT: {
    value: 11,
    displayName: 'Contact'
  },
};


@Exclude()
@HolosenEntityModel({
  apiPrefix: 'tags',
  storeName: 'tags'
})
export class Tag extends BaseEntity {

  @Expose()
  @IsNotEmpty()
  name: string;

  @Expose()
  @IsNotEmpty()
  color: string;

  @Expose()
  @IsNotEmpty()
  type: number;

  @Expose()
  position: string;

  @Expose()
  parent: number;

  @HolosenEntityChild(() => Tag)
  @Expose()
  @Type(() => Tag)
  children: Tag[] = [];


}

