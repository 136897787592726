import {Exclude, Expose, Type} from 'class-transformer';
import {BaseEntity} from '../base/base-entity';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {IsNotEmpty} from 'class-validator';
import {BusinessPartner} from '../business-partner/business-partner.entity';
import {Currency} from '../currency/currency.entity';
import {SalesOrderItem} from '../sales-order-item/sales-order-item.entity';


@Exclude()
@HolosenEntityModel({
  apiPrefix: 'sales/sales-orders',
  storeName: 'salesOrders'
})
export class SalesOrder extends BaseEntity {

  @Expose()
  @IsNotEmpty()
  code: string;

  @Expose()
  phone: string;

  @Expose()
  shippingCharges: number = 0;

  @Expose()
  @Type(() => Currency)
  shippingChargeCurrency;

  @Expose()
  @Type(() => BusinessPartner)
  businessPartner: BusinessPartner;

  @Expose()
  additionalNotes: string;

  @Expose()
  isApproved: boolean = false;

  @Expose()
  @Type(() => SalesOrderItem)
  items: Array<SalesOrderItem> = []


}

