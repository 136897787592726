import {Exclude, Expose} from 'class-transformer';
import {BaseEntity} from '../base/base-entity';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {IsEmail, IsNotEmpty, ValidateIf} from 'class-validator';


@Exclude()
@HolosenEntityModel({
  apiPrefix: 'contacts',
  storeName: 'contacts'
})
export class Contact extends BaseEntity {

  @Expose()
  @IsNotEmpty()
  firstName: string;

  @Expose()
  @IsNotEmpty()
  lastName: string;

  @Exclude()
  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  @Expose()
  title: string;

  @Expose()
  @ValidateIf(o => o.email)
  @IsNotEmpty()
  @IsEmail()
  email: string;

  @Expose()
  phone: string;

  @Expose()
  isPrimary: boolean = false;

  @Expose()
  businessPartner: number;


}

