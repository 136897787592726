import {User} from '../../state-management/entities/user/user.entity';
import {Type} from 'class-transformer';

export class TokenData {
  access_token: string;
  refresh_token: string;
  expires_in: Date;
  scope: string;
  token_type: string;

  @Type(() => User)
  user: User;
}
