import {HolosenEntityMetadata} from './holosen-entity-metadata';
import {HolosenEntityChildMetadata} from './holosen-entity-child-metadata';
import {schema} from 'normalizr';

export class HolosenEntityMetadataStorage {
  private metadataList: Map<string, HolosenEntityMetadata> = new Map();

  public getMetadataList = () => {
    return this.metadataList;
  };

  public getMetadataByName = (entityClassName: string) => {
    return this.metadataList.get(entityClassName);
  };

  public getStoreName = (entityClassName: string) => {
    return this.getMetadataByName(entityClassName).options.storeName;
  };

  public getApiPrefix = (entityClassName: string) => {
    return this.getMetadataByName(entityClassName).options.apiPrefix;
  };

  public getChildClassFunctionByPropertyKey = (entityClassName: string, propertyKey: string): false | Function => {
    const childMetadata = this.getMetadataByName(entityClassName).children;
    if (!childMetadata.has(propertyKey)) {
      return false;
    }
    return childMetadata.get(propertyKey).classFunction;
  };

  public addEntity = (metadata: HolosenEntityMetadata) => {
    const targetName = metadata.target.prototype.constructor.name;
    if (!this.metadataList.has(targetName)) {
      this.metadataList.set(targetName, new HolosenEntityMetadata({target: metadata.target}));
    }
    const entityMetadata = this.metadataList.get(targetName);

    if (!entityMetadata.target) {
      entityMetadata.target = metadata.target;
    }

    if (!entityMetadata.options && metadata.options) {
      entityMetadata.options = metadata.options;
    }

  };

  public addChildToEntity = (target: Object, propertyKey: string, entityChildMetadata: HolosenEntityChildMetadata) => {

    const targetName = target.constructor.name;
    if (!this.metadataList.has(targetName)) {
      this.metadataList.set(targetName, new HolosenEntityMetadata());
    }

    const children = this.metadataList.get(targetName).children;

    if (!children.has(propertyKey)) {
      children.set(propertyKey, entityChildMetadata);
    }

    /*
    Parent Entity Ekliyoruz
     */
    const childClassName = entityChildMetadata.classFunction.name;
    if (!this.metadataList.has(childClassName)) {
      this.metadataList.set(childClassName, new HolosenEntityMetadata({target: entityChildMetadata.classFunction}));
    }
    const childEntityMetadata = this.metadataList.get(childClassName);
    if (!childEntityMetadata.parents.has(targetName)) {
      childEntityMetadata.parents.set(targetName, []);
    }
    childEntityMetadata.parents.set(targetName, [...childEntityMetadata.parents.get(targetName), propertyKey]);

  };

  public initEntitySchemas = () => {
    this.metadataList.forEach((entityMetadata, entityClassName) => {
      this.getEntitySchema(entityClassName);
    });
  };

  private getEntitySchema = (entityClassName: string) => {
    const entityMetadata = this.metadataList.get(entityClassName);
    const entitySchemaName = entityMetadata.options.storeName;
    const entitySchema = new schema.Entity(entitySchemaName);

    if (entityMetadata.children && entityMetadata.children.size > 0) {
      entityMetadata.children.forEach((childMetadata, propertyKey) => {

        if (childMetadata.classFunction.name === entityClassName) {
          if (childMetadata.isArray) {
            entitySchema.define({[propertyKey]: new schema.Array(entitySchema)});
          } else {
            entitySchema.define({[propertyKey]: entitySchema});
          }
          return;
        }

        const childSchema = this.getEntitySchema(childMetadata.name);

        let schemaToAppend;
        if (childMetadata.isArray) {
          schemaToAppend = new schema.Array(childSchema);
        } else {
          schemaToAppend = childSchema;
        }
        entitySchema.define({[propertyKey]: schemaToAppend});
      });
    }

    entityMetadata.schema = entitySchema;
    return entitySchema;
  };
}

export const holosenEntityMetadataStorage = new HolosenEntityMetadataStorage();
