<div class="autocomplete-container">


  <nz-input-group class="autocomplete-input" [ngClass]="{'allow-close': allowClose}" [nzSuffix]="suffixIconSearch">
    <input type="text" nz-input [placeholder]="'COMMON.START_TYPING' | translate" #autocompleteInput/>
  </nz-input-group>
  <ng-template #suffixIconSearch>
    <i class="fa" [ngClass]="{'fa-times-circle': allowClose, 'fa-search': !allowClose}" (click)="closeAutocomplete()"></i>
  </ng-template>
  <ng-container *planbuVar="result$ | async as result">
    <div class="results-panel" *ngIf="!isShort">
      <div class="autocomplete-loading" *ngIf="isLoading">
        <div class="result-item" *ngFor="let item of [] | range:3">
          <nz-skeleton [nzLoading]="true"
                       [nzActive]="true"
                       [nzAvatar]="{size: 'default', shape: 'circle'}"
                       [nzTitle]="{width: '90%'}"
                       [nzParagraph]="false"
          >
          </nz-skeleton>
        </div>
      </div>


      <ng-container *ngIf="!isLoading && result.length > 0">
        <ng-container *ngFor="let item of result">
          <div class="result-item" (click)="selectItem(item)">

            <ng-container *ngIf="resultItemTemplate else defaultResultItem">
              <ng-container *ngTemplateOutlet="resultItemTemplate; context: {$implicit: item}"></ng-container>
            </ng-container>

            <ng-template #defaultResultItem>
              Default Item: {{item}}
            </ng-template>

          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!isLoading && result.length === 0">
        <div class="not-found">

          <div class="result-item">
            <div class="planbu-flex-row">
              <div class="not-found-icon">
                <i class="fa fa-lg fa-exclamation-triangle"></i>
              </div>
              <div class="planbu-flex-width-fill-remaining">
                No matching data
              </div>
            </div>
          </div>
        </div>
      </ng-container>


    </div>

  </ng-container>
</div>
