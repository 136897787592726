import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from './core/core.module';


@NgModule({
  declarations: [],
  exports: [
    CoreModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: []
})
export class SharedImportsModule {

}
