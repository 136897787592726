import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'planbuContrast'
})
export class PlanbuContrastFontPipe implements PipeTransform {

  transform(color: string): any {
    return color && this.getContrastYIQ(color.substr(1)) ? '#fafafa' : '#333333';
  }

  getContrastYIQ = hc => {
    const [r, g, b] = [0, 2, 4].map(p => parseInt(hc.substr(p, 2), 16));
    return ((r * 299) + (g * 587) + (b * 114)) / 1000 < 170;
  };

}
