import {Exclude, Expose, Transform, Type} from 'class-transformer';
import {BaseEntity} from '../base/base-entity';
import * as moment from 'moment';
import {BaseTimeEventDetailType, HolidayDetails, LeaveDetails, MaintenanceDetails, OvertimeDetails, TimeEventDetailTypes, TimeEventType} from './time-event-details';
import {IsDate, IsNotEmpty, IsOptional, ValidateNested} from 'class-validator';

@Exclude()
export abstract class TimeEvent extends BaseEntity {

  @Expose()
  @IsNotEmpty()
  @IsDate()
  @Transform(value => moment(value, 'YYYY-MM-DD HH:mm:ss').toDate(), {toClassOnly: true})
  @Transform((value: Date) => moment(value).format('YYYY-MM-DD HH:mm:ss'), {toPlainOnly: true})
  start: Date = moment().hours(8).minutes(0).toDate();

  @Expose()
  @IsNotEmpty()
  @IsDate()
  @Transform(value => moment(value, 'YYYY-MM-DD HH:mm:ss').toDate(), {toClassOnly: true})
  @Transform((value: Date) => moment(value).format('YYYY-MM-DD HH:mm:ss'), {toPlainOnly: true})
  end: Date = moment().add(1, 'days').hours(17).minutes(0).toDate();


  @Expose()
  @IsNotEmpty()
  @ValidateNested()
  @Type(() => BaseTimeEventDetailType, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'type',
      subTypes: [
        {value: HolidayDetails, name: TimeEventType[TimeEventType.HOLIDAY]},
        {value: LeaveDetails, name: TimeEventType[TimeEventType.LEAVE]},
        {value: OvertimeDetails, name: TimeEventType[TimeEventType.OVERTIME]},
        {value: MaintenanceDetails, name: TimeEventType[TimeEventType.MAINTENANCE]}
      ]
    }
  })
  details: TimeEventDetailTypes;

  @Expose()
  @IsOptional()
  description: string = '';


  @Expose()
  abstract type: TimeEventType;

}
