import {BaseEntity} from '../entities/base/base-entity';
import {HolosenStateManagementService} from './holosen-state-management.service';
import {Observable, of, throwError} from 'rxjs';
import {
  HolosenStateManagementSelectOptions,
  HolosenStateManagementUpsertOptions
} from '../interfaces/holosen-state-management-options';
import {HolosenHttpService, HTTP_METHODS, IHolosenHttpServiceResponse} from '../../core/services/holosen-http.service';
import {InjectorInstance} from '../../core/holosen-injector.module';
import {EntityPaginationConfig} from '../entity-pagination';
import {HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {AppConfig} from '../../app.config';
import {holosenEntityMetadataStorage} from '../metadata/holosen-entity-metadata-storage';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {HolosenEntityPaginationResponse} from '../interfaces/holosen-entity-pagination-response';
import {classToPlain, plainToClass} from 'class-transformer';
import * as camelcaseKeys from 'camelcase-keys';
import {filterByID, filterWhere} from '../helpers/helpers';
import * as snakecaseKeys from 'snakecase-keys';
import {ActivatedRoute, Router} from '@angular/router';
import {HolosenAuthenticationService} from '../../core/services/holosen-authentication.service';

export abstract class BaseEntityService<T extends BaseEntity> {

  protected abstract readonly STATE_MODEL: new() => T;
  protected stateManager: HolosenStateManagementService;
  protected httpService: HolosenHttpService;
  protected route: ActivatedRoute;
  protected router: Router;

  protected constructor() {
    this.stateManager = InjectorInstance.get<HolosenStateManagementService>(HolosenStateManagementService);
    this.httpService = InjectorInstance.get<HolosenHttpService>(HolosenHttpService);
    this.route = InjectorInstance.get<ActivatedRoute>(ActivatedRoute);
    this.router = InjectorInstance.get<Router>(Router);

  }

  /*
  SERVER METHODS
   */

  /*
  ********* FETCH REMOTE
   */

  public fetchRemote = (entityID?: number | 'all', options: { params?: any } = {}) => {
    let url = AppConfig.URL.API + holosenEntityMetadataStorage.getApiPrefix(this.STATE_MODEL.name) + '/';
    if (entityID && entityID !== 'all') {
      url += entityID + '/';
    }
    let params = new HttpParams({
      fromObject: options.params
    });

    if (entityID === 'all') {
      params = params.append('ga', '1');
    }


    return this.httpService.get(url, {params}).pipe(
      switchMap((httpResult: IHolosenHttpServiceResponse) => {

        const convertedPayload = plainToClass(this.STATE_MODEL, camelcaseKeys(httpResult, {deep: true}), {groups: ['fromAPI']});
        return this.stateManager.upsert(this.STATE_MODEL, convertedPayload);
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  };

  /*
  ********* PAGINATE REMOTE
 */

  public paginateRemote = (pagination: EntityPaginationConfig, options?: { changeURL?: boolean, extra?: { [key: string]: any } }): Observable<boolean> => {
    const url = AppConfig.URL.API + holosenEntityMetadataStorage.getApiPrefix(this.STATE_MODEL.name) + '/';
    let params = new HttpParams({
      fromObject: pagination.serializeToQueryString()
    });

    if (options && options.extra) {
      Object.keys(options.extra).forEach(key => params = params.append(key, options.extra[key]));
    }


    return this.httpService.get(url, {params}).pipe(
      switchMap((httpResult: any) => {
        httpResult = {
          ...httpResult,
          data: plainToClass(this.STATE_MODEL, camelcaseKeys(httpResult.data, {deep: true}), {groups: ['fromAPI']})

        };

        console.log(`[httpResult] `, httpResult);

        return this.stateManager.upsert(this.STATE_MODEL, httpResult, {paginated: true});

      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  };


  /*
  ********* UPSERT REMOTE
  */
  public upsertRemote = (entity: T, options?: { upload?: { file: File, name: string } }): Observable<any> => {
    const url = `${AppConfig.URL.API + holosenEntityMetadataStorage.getApiPrefix(this.STATE_MODEL.name)}${entity.id ? '/' + entity.id : ''}/`;

    const hasUpload = options && options.upload;

    const requestPayload = snakecaseKeys(classToPlain(entity, {groups: ['toAPI']}), {deep: true});
    const formData = new FormData();
    if (hasUpload) {
      Object.keys(requestPayload).forEach((key) => {
        formData.append(key, requestPayload[key] as any);
      });
      formData.append(options.upload.name, options.upload.file, options.upload.file.name);
      if (entity.id) {
        formData.append('_method', 'PUT');
      }
    }


    const httpRequest = entity.id /*&& !hasUpload*/ ? this.httpService.put : this.httpService.post;

    return httpRequest(url, hasUpload ? formData : requestPayload).pipe(
      switchMap((httpResult: IHolosenHttpServiceResponse) => {
        const convertedPayload = plainToClass(this.STATE_MODEL, camelcaseKeys(httpResult, {deep: true}), {groups: ['fromAPI']});
        return this.stateManager.upsert(this.STATE_MODEL, convertedPayload).pipe(
          map(_ => convertedPayload.id)
        );
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  };


  /*
  ********* AUTOCOMPLETE
  */
  public autocomplete = (keyword: string) => {
    if (keyword.trim() === '') {
      return of([]);
    }
    const url = AppConfig.URL.API + holosenEntityMetadataStorage.getApiPrefix(this.STATE_MODEL.name) + '/';

    const params = new HttpParams().append('keyword', keyword);

    return this.httpService.get(url, {params}).pipe(
      map((httpResult: IHolosenHttpServiceResponse) => {
          return plainToClass(this.STATE_MODEL, camelcaseKeys(httpResult, {deep: true}), {groups: ['fromAPI']});
        },
        catchError(error => {
          return throwError(error);
        }))
    );
  };


  /*
  ********* DELETE REMOTE
  */
  public deleteRemote = (entityID?: number, options: { params?: any } = {}) => {
    let url = `${AppConfig.URL.API + holosenEntityMetadataStorage.getApiPrefix(this.STATE_MODEL.name)}/${entityID}/`;

    let params = new HttpParams({
      fromObject: options.params
    });


    return this.httpService.delete(url, {params}).pipe(
      switchMap((httpResult: any) => {
        return this.stateManager.delete(this.STATE_MODEL, entityID);
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  };


  /*
  STORE METHODS
   */

  public paginate = (): Observable<HolosenEntityPaginationResponse<T>> => {
    return this.stateManager.paginate(this.STATE_MODEL).pipe(
      // tap(result => console.log(`${this.STATE_MODEL.name}`, result))
    );
  };

  public select = (id?: number | number[]): Observable<T[]> => {
    const options: HolosenStateManagementSelectOptions = {};
    if (id) {
      options.filterFunction = filterByID(id);
    }
    return this.stateManager.select(this.STATE_MODEL, options);
  };

  public selectByID = (id: number): Observable<T> => {
    return this.select(id).pipe(
      map(entities => entities[0])
    );
  };

  public selectWhere = (property: string, search: any): Observable<T[]> => {
    return this.select().pipe(
      filterWhere(property, search)
    );
  };

  public selectByIdList = (ids: number[]): Observable<T[]> => {
    return this.select(ids);
  };

  public upsert = (
    payload: T | T[],
    options: HolosenStateManagementUpsertOptions = {
      onlySelf: false,
      paginated: false
    }) => {
    this.stateManager.upsert(this.STATE_MODEL, payload, options).subscribe(
      // (upsertResult) => console.log('[upsertResult]', upsertResult)
    );
  };
}
