import {Component, Input, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import {BaseEntityService} from '../../../state-management/services/base-entity.service';
import {Media} from '../../../state-management/entities/media/media.entity';
import {
  NzMessageService,
  NzModalComponent,
  NzModalRef,
  NzModalService,
  NzNotificationService,
  UploadFile
} from 'ng-zorro-antd';
import {UserEntityService} from '../../../state-management/entities/user/user-entity.service';
import {TranslateService} from '@ngx-translate/core';
import {takeUntil} from 'rxjs/operators';
import {NgForm} from '@angular/forms';
import {Subject} from 'rxjs';
import {MediaEntityService} from '../../../state-management/entities/media/media-entity.service';

@Component({
  selector: 'app-attachment-upsert',
  templateUrl: './attachment-upsert.component.html',
  styleUrls: ['./attachment-upsert.component.less']
})
export class AttachmentUpsertComponent implements OnInit, OnDestroy {

  @Input()
  entityService;

  @Input()
  attachmentID: number;

  @Input()
  attachableID: number;

  attachment: Media = new Media();

  selectedFile = [];

  @ViewChild('upsertForm', {static: true})
  upsertForm: NgForm;


  private onDestroy$: Subject<void> = new Subject<void>();
  isUpsertLoading = false;

  modalRefInstance: NzModalComponent;

  constructor(@Optional() private modalRef: NzModalRef<AttachmentUpsertComponent>,
              private mediaService: MediaEntityService,
              private modalService: NzModalService,
              private notificationService: NzNotificationService,
              private messageService: NzMessageService,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    this.modalRefInstance = this.modalRef.getInstance();

    if (!this.attachmentID) {
      this.setModalTitle();
    } else {
      this.mediaService.selectByID(this.attachmentID)
        .pipe(
          takeUntil(this.onDestroy$)
        )
        .subscribe((selectedEntity) => {
          this.attachment = selectedEntity;
          this.setModalTitle();
        });
    }
  }


  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }


  private setModalTitle = () => {
    if (this.modalRef) {
      const modalInstance = this.modalRefInstance;
      if (!modalInstance.nzTitle) {
        if (this.attachment.id) {
          modalInstance.nzTitle = 'Ek Düzenle';
        } else {
          modalInstance.nzTitle = 'Yeni Ek Oluştur';

        }
      }
    }
  };


  upsertAttachment = () => {
    this.isUpsertLoading = true;
    this.modalRefInstance.nzClosable = false;
    this.attachment.validate()
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe((isValid) => {
        if (!isValid) {
          this.isUpsertLoading = false;
          this.modalRefInstance.nzClosable = true;

          return;
        }


        let options = null;
        if (this.selectedFile.length > 0) {
          console.log(`[this.selectedFile[0]] `, this.selectedFile[0]);
          options = {
            upload: {
              file: this.selectedFile[0],
              name: 'file'
            }
          };
        }


        this.entityService.upsertAttachmentRemote(this.attachableID, this.attachment, options).subscribe(
          (updateRemoteResult) => {
            this.upsertForm.form.markAsUntouched();
            this.messageService.success('Ek Kaydedildi');
            this.modalRef.triggerOk();
            this.cancel();
            return;
          },
          (error => {
            this.notificationService.error('Bir Hata Oluştu', 'Ek kaydedilemedi');
            this.isUpsertLoading = false;
            this.modalRefInstance.nzClosable = true;
          })
        );

      });

  };


  cancel = () => {
    this.modalRef.triggerCancel();
  };

  onCancel = () => {
    return new Promise((resolve) => {
      if (this.isUpsertLoading) {
        resolve(false);
      }
      if (this.upsertForm.touched) {
        this.modalService.confirm({
          nzTitle: 'Emin misiniz?',
          nzContent: 'Kaydedilmemiş bilgiler kaybolacak',
          nzOkText: 'Evet',
          nzCancelText: 'Hayır',
          nzOnOk: () => resolve(true)
        });
      } else {
        resolve(true);
      }
    });

  };

  fileSelected = (file: UploadFile): boolean => {
    this.selectedFile = [file];
    this.attachment.file = file;
    return false;
  };

}
