import {Exclude, Expose, Transform, Type} from 'class-transformer';
import * as moment from 'moment';
import {HasError} from '../../mixins/has-error';
import {use} from 'typescript-mix';


export interface BaseEntity extends HasError {
}

export abstract class BaseEntity {

  @use(HasError)
  this;

  @Exclude()
  _errors = [];

  @Exclude()
  _defaultValidatorOptions = {validationError: {target: false}, forbidUnknownValues: false};

  @Expose()
  public id: number;


  @Exclude({toPlainOnly: true})
  @Type(() => Date)
  @Transform(value => moment(value, 'YYYY-MM-DD HH:mm:ss'))
  createdAt: moment.Moment;

  @Exclude({toPlainOnly: true})
  @Type(() => Date)
  @Transform(value => moment(value, 'YYYY-MM-DD HH:mm:ss'))
  updatedAt: moment.Moment;

  @Exclude({toPlainOnly: true})
  @Type(() => Date)
  @Transform(value => moment(value, 'YYYY-MM-DD HH:mm:ss'))
  deletedAt: moment.Moment;

  @Expose()
  isActive: boolean;

  @Exclude({toPlainOnly: true})
  createdBy: number;

  @Exclude({toPlainOnly: true})
  deletedBy: number;

  @Exclude({toPlainOnly: true})
  updatedBy: number;

}

