<div>
  <form nz-form
        nzLayout="vertical"
        #upsertForm="ngForm"
  >


    <nz-form-item>
      <nz-form-label>Faaliyet Adı</nz-form-label>
      <nz-form-control
        [nzValidateStatus]="activity.hasError('name') ? 'error' : ''"
        [nzErrorTip]="activity.getErrorMessage('name')"
      >
        <input nz-input type="text" name="name" [(ngModel)]="activity.name">
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Açıklama</nz-form-label>
      <nz-form-control
        [nzValidateStatus]="activity.hasError('description') ? 'error' : ''"
        [nzErrorTip]="activity.getErrorMessage('description')"
      >
        <textarea rows="2" nz-input name="addressLine" [(ngModel)]="activity.description"></textarea>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="!HIDDEN_TYPES.includes(activitySourceType)">
      <nz-form-label>Faaliyet Türü</nz-form-label>
      <nz-form-control
        [nzValidateStatus]="activity.hasError('type') ? 'error' : ''"
        [nzErrorTip]="activity.getErrorMessage('type')"
      >
        <nz-select
          name="unit"
          nzShowSearch
          nzPlaceHolder="Faaliyet türü seçin"
          [(ngModel)]="activity.type"
        >
          <nz-option
            *ngFor="let activityType of ACTIVITY_TYPES[activitySourceType] | toArray"
            [nzLabel]="activityType.name"
            [nzValue]="activityType.value"
          >
            {{activityType.name}}
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Faaliyet Sorumlusu</nz-form-label>
      <nz-form-control
        [nzValidateStatus]="activity.hasError('responsible') ? 'error' : ''"
        [nzErrorTip]="activity.getErrorMessage('responsible')"
      >


        <app-planbu-autocomplete-selection
          [entity]="activity.responsible"
          [selectedItemTemplate]="responsibleTemplate"
          [autocompleteItemTemplate]="responsibleTemplate"
          [autocompleteDataSource]="responsibleAutocompleteDataSource"
          (entitySelected)="responsibleSelected($event)"
        >
        </app-planbu-autocomplete-selection>

        <ng-template let-item #responsibleTemplate>
          <ng-container *planbuVar="toEmployee(item) as employee ">
            <div class="planbu-flex-row selected-employee-container">
              <div class="result-item-avatar">
                <nz-avatar
                  [nzSize]="'default'"
                  [nzIcon]="'user'"
                  [nzSrc]="employee.avatar"
                >
                </nz-avatar>
              </div>
              <div class="result-item-name planbu-flex-width-fill-remaining">
                <div>{{employee.name}} <small>({{employee.email}})</small></div>
              </div>
            </div>
          </ng-container>
        </ng-template>

      </nz-form-control>
    </nz-form-item>



    <nz-form-item>
      <nz-form-label>Planlanan Uygulama Tarihi</nz-form-label>
      <nz-form-control
        [nzValidateStatus]="activity.hasError('deadline') ? 'error' : ''"
        [nzErrorTip]="activity.getErrorMessage('deadline')"
      >
        <nz-date-picker
          nzShowTime
          nzFormat="dd.MM.yyyy HH:mm"
          nzPlaceHolder="Select Time"
          name="deadline"
          [(ngModel)]="activity.deadline"
          [nzShowTime]="{nzFormat: 'HH:mm'}"
        ></nz-date-picker>

      </nz-form-control>
    </nz-form-item>

  </form>
</div>

<div *nzModalFooter>
  <button
    nz-button
    nzType="default"
    [disabled]="isUpsertLoading"
    (click)="cancel()"
  >
    {{'COMMON.CANCEL' | translate}}
  </button>
  <button
    nz-button
    nzType="primary"
    (click)="upsertActivity()"
    [nzLoading]="isUpsertLoading"
  >
    {{(activity.id ? 'Güncelle' : 'Kaydet') | translate}}
  </button>
</div>
