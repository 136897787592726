import {Exclude, Expose, Transform, Type} from 'class-transformer';
import {User} from '../user/user.entity';
import {WorkCenter} from '../work-center/work-center.entity';
import {IsNotEmpty} from 'class-validator';
import {HasID} from '../../decorators/has-id';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {HolosenEntityChild} from '../../decorators/HolosenEntityChild';

export enum TimeEventType {
  HOLIDAY = 1,
  LEAVE = 2,
  OVERTIME = 3,
  MAINTENANCE = 4
}


export type TimeEventDetailTypes = HolidayDetails | LeaveDetails | OvertimeDetails | MaintenanceDetails;

@Exclude()
export abstract class BaseTimeEventDetailType {
  @Expose()
  abstract type: TimeEventType;

  @Expose()
  id: number;

}

@Exclude()
export class HolidayDetails extends BaseTimeEventDetailType {
  @Expose()
  @IsNotEmpty()
  name: string;

  @Expose()
  @IsNotEmpty()
  type = TimeEventType.HOLIDAY;
}


@HolosenEntityModel({
  storeName: 'leaveDetails',
  apiPrefix: 'leave-details'
})
@Exclude()
export class LeaveDetails extends BaseTimeEventDetailType {

  @Expose()
  @IsNotEmpty()
  @HasID()
  @Type(() => User)
  @HolosenEntityChild(() => User)
    // @Transform(u => u.id, {groups: ['toAPI']})
  employee: User;

  @Expose()
  @IsNotEmpty()
  type = TimeEventType.LEAVE;
}

@Exclude()
export class OvertimeDetails extends BaseTimeEventDetailType {
  @Expose()
  @IsNotEmpty()
  @Type(() => User)
  employee: User;

  @Expose()
  @IsNotEmpty()
  type = TimeEventType.OVERTIME;
}

@Exclude()
export class MaintenanceDetails extends BaseTimeEventDetailType {
  @Expose()
  @IsNotEmpty()
  @Type(() => WorkCenter)
  workCenter: WorkCenter;

  @Expose()
  @IsNotEmpty()
  type = TimeEventType.MAINTENANCE;
}
