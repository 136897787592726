import {Exclude, Expose} from 'class-transformer';
import {BaseEntity} from '../base/base-entity';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {IsNotEmpty} from 'class-validator';

@Exclude()
@HolosenEntityModel({
  apiPrefix: 'taxes',
  storeName: 'taxes'
})
export class Tax extends BaseEntity {

  @Expose()
  @IsNotEmpty()
  name: string;


  @Expose()
  @IsNotEmpty()
  rate: number;


}

