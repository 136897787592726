import {AfterContentInit, AfterViewInit, ContentChildren, Directive, ElementRef, forwardRef, OnInit, Optional, QueryList, ViewChildren} from '@angular/core';
import {NavigationEnd, Router, RouterLinkActive} from '@angular/router';
import {debounceTime, filter} from 'rxjs/operators';


export interface MenuItem {
    text: string;
    url?: string;
    icon?: string;
    prefix?: string;
    children?: Array<MenuItem>;

}


@Directive({
    selector: '[appMenuItem]',
    exportAs: 'appMenuItem'
})
export class MenuItemDirective implements OnInit, AfterViewInit, AfterContentInit {

    isActive = false;

    @ContentChildren(RouterLinkActive, {descendants: true})
    routerLinkList: QueryList<RouterLinkActive>;

    constructor(private router: Router,
                private elementRef: ElementRef,
                @Optional() private selfRLA: RouterLinkActive) {

    }

    ngOnInit() {

    }

    ngAfterViewInit(): void {

    }

    ngAfterContentInit(): void {
        this.subscribeToRouterEvents();
        setTimeout(() => {
            this.checkIsActive();
        }, 50);
    }


    subscribeToRouterEvents = () => {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            debounceTime(50)
        ).subscribe(_ => this.checkIsActive());
    };

    checkIsActive = () => {
        const rlaList = [...this.routerLinkList];
        if (this.selfRLA) {
            rlaList.push(this.selfRLA);
        }
        this.isActive = rlaList.reduce((prevValue: boolean, curValue: RouterLinkActive) => {
            prevValue = prevValue || curValue.isActive;
            return prevValue;
        }, false);

    };


}
