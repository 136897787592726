import {Exclude, Expose, Transform, Type} from 'class-transformer';
import {User} from '../user/user.entity';
import {BaseEntity} from '../base/base-entity';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {IsNotEmpty} from 'class-validator';


@Exclude()
@HolosenEntityModel({
  apiPrefix: 'media',
  storeName: 'media'
})
export class Media extends BaseEntity {

  @Expose()
  @IsNotEmpty()
  name: string;

  @Expose()
  description: string;

  @Expose()
  @IsNotEmpty()
  file: any;


}

