import {Exclude, Expose} from 'class-transformer';
import {BaseEntity} from '../base/base-entity';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';


@Exclude()
@HolosenEntityModel({
  apiPrefix: 'common/currencies',
  storeName: 'currencies'
})
export class Currency extends BaseEntity {

  @Expose()
  name: string;

  @Expose()
  code: string;



}

