import {BaseEntity} from './entities/base/base-entity';
import {EntityPagination} from './entity-pagination';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {distinctUntilChanged, map, switchMap, take, tap} from 'rxjs/operators';
import * as cloneDeep from 'lodash/cloneDeep';
import {
  HolosenStateManagementEntitySelectOptions,
  HolosenStateManagementEntityUpsertOptions
} from './interfaces/holosen-state-management-options';
import {filterByID} from './helpers/helpers';

interface EntityStateModel<T extends BaseEntity> {
  [key: string]: T;
};

export class EntityState<T extends BaseEntity> {
  private _entities: BehaviorSubject<EntityStateModel<T>> = new BehaviorSubject({} as EntityStateModel<T>);
  private _entitiesObservable = this._entities.asObservable();
  private _ids: number[] = [];
  private readonly cache$ = this._entities.pipe(distinctUntilChanged());

  pagination: EntityPagination = new EntityPagination();


  constructor(private STATE_MODEL: new() => T,
              private checkDeletionPermission: (...args) => any) {
  }

  private setState = (newState: EntityStateModel<T>) => {
    this._ids = Object.keys(newState).map(id => +id);
    this._entities.next({...newState} as EntityStateModel<T>);
  };

  private get entities(): Observable<EntityStateModel<T>> {
    return this._entitiesObservable.pipe(take(1));
  };

  public hasChildren(propertyKey: string, id: number) {
    return Object.values(this._entities.getValue()).some(entity => {
      if (Array.isArray(entity[propertyKey])) {
        return (entity[propertyKey] as any[]).includes(id);
      } else {
        return entity[propertyKey] === id;
      }
    });
  }


  public select = (options: HolosenStateManagementEntitySelectOptions = {paginated: false}): Observable<Array<T>> => {
    return this.cache$.pipe(
      // map((entities) => Object.values(entities)),
      map((entities) => cloneDeep(Object.values(entities))),
      switchMap((result) => {
        if (!options.paginated) {
          return of(result);
        }
        return of(result).pipe(filterByID(this.pagination.resultIds));
      }),
      options.filterFunction ? options.filterFunction : (result) => result
    );
  };


  public upsert = (payload: T | T[], options: HolosenStateManagementEntityUpsertOptions = {paginated: false}): Observable<EntityStateModel<T>> => {

    if (Array.isArray(payload)) {
      return this.upsertCollection(payload, options);
    } else {
      return this.upsertSingle(payload);
    }
  };

  private upsertCollection = (payload: T[], options: HolosenStateManagementEntityUpsertOptions = {paginated: false}): Observable<EntityStateModel<T>> => {
    return this.entities.pipe(
      tap((currentState) => {

        let newState = {...currentState};

        // TODO
        // BU KISIM PAGINATION İŞLEMİNDEN SONRA STATE'İ SIFIRLAMAK İÇİN KULLANILABİLİR.
        // let newState = {}
        if (options.paginated) {
          const idsToDelete = this.checkDeletionPermission(this.STATE_MODEL.name, this._ids);
          for (let id of idsToDelete) {
            delete newState[id];
          }
        }


        for (const entity of payload) {
          newState = {
            ...newState,
            [String(entity.id)]: entity
          };
        }
        if (options.paginated) {
          this.pagination.resultIds = payload.map(entity => +entity.id);
          this.pagination.orderBy = options.paginationResult.orderBy;
          this.pagination.result = options.paginationResult;
        }

        // TODO
        // BU KISIM PAGINATION İŞLEMİNDEN SONRA STATE'İ SIFIRLAMAK İÇİN KULLANILABİLİR.
        // else {
        //   newState = {...currentState, ...newState};
        // }
        this.setState(newState);
      }),
      switchMap(() => this.entities)
    );
  };

  private upsertSingle = (payload: T): Observable<EntityStateModel<T>> => {
    return this.entities.pipe(
      tap((currentState) => {
        this.setState({
          ...currentState,
          [String(payload.id)]: payload
        });

      }),
      switchMap(() => this.entities)
    );
  };

  public delete = (payload: number | number[], force = false): Observable<any> => {
    if (Array.isArray(payload)) {
      return this.deleteCollection(payload, force);
    } else {
      return this.deleteSingle(payload, force);
    }
  };


  private deleteCollection = (ids: number[], force): Observable<number[]> => {
    const idsToDelete = force ? ids : this.checkDeletionPermission(this.STATE_MODEL.name, ids);
    return this.entities.pipe(
      tap(currentState => {
        let newState = {...currentState};
        for (let id of idsToDelete) {
          delete newState[id];
        }
        this.setState(newState);
      }),
      map(_ => ids)
    );

  };

  private deleteSingle = (id: number, force): Observable<number> => {
    const hasDeletePermission = this.checkDeletionPermission(this.STATE_MODEL.name, [id]).includes(id);
    if (hasDeletePermission || force) {
      return this.entities.pipe(
        tap(currentState => {
          let newState = {...currentState};
          delete newState[id];
          this.setState(newState);
        }),
        map(_ => id)
      );
    }
    return of(null);

  };


  /*
ONLY FOR DEBUG PURPOSES
 */
  public getDevtoolsValue = () => {
    return {
      entities: this._entities.getValue(),
      ids: this._ids,
      pagination: this.pagination
    };
  };

}
