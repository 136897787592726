import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {fromEvent, iif, Observable, of, Subject} from 'rxjs';
import {BaseEntity} from '../../../state-management/entities/base/base-entity';
import {debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'app-planbu-autocomplete',
  templateUrl: './planbu-autocomplete.component.html',
  styleUrls: ['./planbu-autocomplete.component.less']
})
export class PlanbuAutocompleteComponent<T> implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  dataSource: (keyword: string) => Observable<T[]>;

  @Input()
  minSearchLength = 3;

  @Input()
  allowClose: boolean = false;

  @Output()
  itemSelected = new EventEmitter<T>();

  @Output()
  autocompleteClosed = new EventEmitter<void>();

  @ViewChild('autocompleteInput', {static: true})
  autocompleteInput: ElementRef;

  @ContentChild(TemplateRef, {static: true})
  resultItemTemplate: TemplateRef<any>;

  onDestroy$ = new Subject<void>();

  result$: Observable<T[]>;

  isLoading = false;
  isInputFocused = false;
  isShort = true;

  constructor() {
  }

  ngOnInit() {
    if (!this.dataSource) {
      console.error('PlanbuAutocompleteComponent Data Source Must Be Passed!');
    }
  }

  ngAfterViewInit(): void {
    this.subscribeToAutocompleteInput();
    this.autocompleteInput.nativeElement.focus();
  }


  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

  subscribeToAutocompleteInput = () => {


    this.result$ = fromEvent(this.autocompleteInput.nativeElement, 'input').pipe(
      debounceTime(300),
      map((event: KeyboardEvent) => (<HTMLInputElement> event.target).value),
      map(keyword => keyword.trim()),
      distinctUntilChanged(),
      switchMap(keyword => {
          if (keyword.length >= this.minSearchLength) {
            this.isShort = false;
            this.isLoading = true;
            return this.dataSource(keyword);
          } else {
            this.isShort = true;
            return of([] as T[]);
          }
        }
      ),
      tap(_ => this.isLoading = false)
    );

    fromEvent(this.autocompleteInput.nativeElement, 'focus').pipe(takeUntil(this.onDestroy$)).subscribe(_ => this.isInputFocused = true);
    fromEvent(this.autocompleteInput.nativeElement, 'blur').pipe(takeUntil(this.onDestroy$)).subscribe(_ => this.isInputFocused = false);
  };


  selectItem = (item: T) => {
    console.log('selectItem called');
    this.itemSelected.emit(item);
  };

  closeAutocomplete = () => {
    if (this.allowClose) {
      this.autocompleteClosed.emit();
    }
  };

}
