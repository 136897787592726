import {finalize, map, switchMap, tap} from 'rxjs/operators';
import {BaseEntity} from '../entities/base/base-entity';
import {defer, Observable, of, Subject} from 'rxjs';

export const mapIncludesFalse = <T extends BaseEntity>() => map((result: Array<T | false>) => {
  if (result.includes(false)) {
    return false;
  }
  return result as T[];
});


export const filterByID = <T extends BaseEntity>(criteria: number | number[]) => switchMap((entities: T[]) => {
  return of(Object.values(entities).filter((entity) => Array.isArray(criteria) ? criteria.includes(entity.id) : criteria === entity.id));
});

export const filterWhere = <T extends BaseEntity>(property: string, search: any) => switchMap((entities: T[]) => {
  if (search === null) {
    return of(Object.values(entities).filter((entity) => !entity[property] || entity[property] === null));
  } else {
    return of(Object.values(entities).filter((entity) => entity[property] && entity[property] === search));
  }
});


export function prepare<T>(callback: () => void): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> => defer(() => {
    callback();
    return source;
  });
}

export function indicate<T>(indicator: Subject<boolean>): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> => source.pipe(
    prepare(() => indicator.next(true)),
    finalize(() => indicator.next(false))
  );
}

