import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'sanitizer'
})
export class PlanbuSanitizerPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: string, type: 'html' | 'style' | 'script' | 'url' | 'resourceUrl') {
    switch (type) {
      case 'html': {
        return this.sanitizer.bypassSecurityTrustHtml(value);
      }
      case 'resourceUrl': {
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      }
      case 'script': {
        return this.sanitizer.bypassSecurityTrustScript(value);
      }
      case 'style': {
        return this.sanitizer.bypassSecurityTrustStyle(value);
      }
      case 'url': {
        return this.sanitizer.bypassSecurityTrustUrl(value);
      }
      default: {
        return this.sanitizer.bypassSecurityTrustHtml(value);
      }
    }
  }

}
