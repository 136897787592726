import 'reflect-metadata';
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppRoutingModule} from './routing/app-routing.module';
import {AppComponent} from './app.component';
import {IconsProviderModule} from './icons-provider.module';
import {en_US, NZ_I18N} from 'ng-zorro-antd';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {registerLocaleData} from '@angular/common';
import en from '@angular/common/locales/en';
import {LayoutModule} from './layout/layout.module';
import {HolosenStateManagementModule} from './state-management/holosen-state-management.module';
import {CoreModule} from './core/core.module';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import {HolosenInjectorModule} from './core/holosen-injector.module';
import {StorageModule} from '@ngx-pwa/local-storage';
import {AppInitService} from './app-init/app-init.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

registerLocaleData(en);

export function initApplication(appInitService: AppInitService) {
  return () => appInitService.initApp();
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    // AppInitModule,
    HolosenInjectorModule,
    StorageModule.forRoot({IDBNoWrap: false,}),
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    LayoutModule,
    HolosenStateManagementModule,
    IconsProviderModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {provide: NZ_I18N, useValue: en_US},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: APP_INITIALIZER, useFactory: initApplication, deps: [AppInitService], multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

