export enum PAGINATION_ORDER {
  ASCENDING = 'ASC' as any,
  DESCENDING = 'DESC' as any
}

export class EntityPaginationConfig {


  public length: number;
  public page: number;
  public pageSize: number;
  public pageSizeOptions: number[];
  public orderDirection: PAGINATION_ORDER = null;
  public orderBy: string = null;
  public keyword: string = null;
  public filter: any = {};


  constructor(options: { length?: number, page?: number, pageSize?: number, pageSizeOptions?: number[] } = {}) {
    this.length = options.length || 0;
    this.page = options.page || 1;
    this.pageSize = options.pageSize || 10;
    this.pageSizeOptions = options.pageSizeOptions || [10, 25, 50, 100];
  }

  public serializeToQueryString = (): any => {

    const serializedQuery: any = {} as any;

    if (this.page) {
      serializedQuery.pg = this.page.toString();
    }

    if (this.pageSize) {
      serializedQuery.pp = this.pageSize.toString();
    }

    if (this.orderDirection) {
      serializedQuery.od = this.orderDirection.toString();
    }

    if (this.orderBy) {
      serializedQuery.ob = this.orderBy.toString();
    }

    if (this.keyword && this.keyword.toString().trim() !== '') {
      serializedQuery.keyword = this.keyword.toString().trim();
    }

    Object.keys(this.filter).forEach(key => {
      if (this.filter[key]) {
        const value = this.filter[key];
        serializedQuery[`${key}`] = value.toString();
      }
    });

    return serializedQuery;
  };

  public deserializeFromQueryString = (query: any = {}): void => {


    this.page = +query.pg || 1;

    this.pageSize = +query.pp || 10;

    if (query.od) {
      this.orderDirection = query.od;
    }

    if (query.ob) {
      this.orderBy = query.ob;
    }

    if (query.keyword) {
      this.keyword = query.keyword.trim();
    }


    // Object.keys(this.filter).forEach(key => {
    //   const value = this.filter[key];
    //   serializedQuery[`filter[${key}]`] = value.toString();
    // });

  };

}

export class EntityPagination {
  resultIds: number[] = [];
  result: any = {};
  orderBy: any[] = [];
  // config: EntityPaginationConfig = new EntityPaginationConfig();
}
