import {TimeEvent} from '../../time-event.entity';
import {HolosenEntityModel} from '../../../../decorators/HolosenEntityModel';
import {Expose} from 'class-transformer';
import {LeaveDetails, TimeEventType} from '../../time-event-details';


@HolosenEntityModel({
  apiPrefix: 'capacity/time-events',
  storeName: 'leaves'
})
export class Leave extends TimeEvent {

  @Expose()
  type = TimeEventType.LEAVE;

  @Expose()
  // @HolosenEntityChild(() => LeaveDetails)
  details: LeaveDetails = new LeaveDetails();
}
