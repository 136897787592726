import {Injectable} from '@angular/core';
import {BaseEntityService} from '../../services/base-entity.service';
import {Activity} from './activity.entity';
import {Observable, throwError} from 'rxjs';
import {AppConfig} from '../../../app.config';
import {holosenEntityMetadataStorage} from '../../metadata/holosen-entity-metadata-storage';
import * as snakecaseKeys from 'snakecase-keys';
import {classToPlain, plainToClass} from 'class-transformer';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {IHolosenHttpServiceResponse} from '../../../core/services/holosen-http.service';
import * as camelcaseKeys from 'camelcase-keys';

@Injectable({
  providedIn: 'root'
})
export class ActivityEntityService extends BaseEntityService<Activity> {

  protected readonly STATE_MODEL = Activity;

  constructor() {
    super();
  }

  public setStatusRemote = (activity: Activity, status: number): Observable<any> => {
    const url = `${AppConfig.URL.API + holosenEntityMetadataStorage.getApiPrefix(this.STATE_MODEL.name)}/${activity.id}/set-status/`;

    return this.httpService.put(url, {status}).pipe(
      switchMap((httpResult: IHolosenHttpServiceResponse) => {
        const convertedPayload = plainToClass(Activity, camelcaseKeys(httpResult, {deep: true}), {groups: ['fromAPI']});
        return this.stateManager.upsert(Activity, convertedPayload).pipe(
          map(_ => convertedPayload.id)
        );
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  };

}
