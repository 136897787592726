import {Pipe, PipeTransform} from '@angular/core';
import {LAYOUT_COLORS} from './colors';

@Pipe({
  name: 'getBgColor'
})
export class GetBgColorPipe implements PipeTransform {

  transform(index?: number): any {
    const i = index ? index : Math.floor(Math.random() * LAYOUT_COLORS.length);
    return LAYOUT_COLORS[i];
  }

}
