<div class="color-popover-container">
  <div nz-row>
    <div nz-col nzSpan="8" *ngFor="let color of colors" (click)="selectColor(color)">
      <div class="popover-color-box"
           [style.background-color]="color"
           [style.color]="color | planbuContrast">
        <i class="fa fa-check" *ngIf="color === selectedColor"></i>
      </div>
    </div>
  </div>

</div>
