import {TimeEvent} from '../../time-event.entity';
import {HolidayDetails, TimeEventType} from '../../time-event-details';
import {Expose} from 'class-transformer';
import {HolosenEntityModel} from '../../../../decorators/HolosenEntityModel';


@HolosenEntityModel({
  apiPrefix: 'capacity/time-events',
  storeName: 'holidays'
})
export class Holiday extends TimeEvent {

  @Expose()
  type = TimeEventType.HOLIDAY;

  @Expose()
  details: HolidayDetails = new HolidayDetails();
}
