import {Exclude, Expose, Transform} from 'class-transformer';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {BaseEntity} from '../base/base-entity';
import {IsDefined, IsNotEmpty, IsNumber, IsPositive, Min, ValidateIf} from 'class-validator';

@Exclude()
@HolosenEntityModel({
  apiPrefix: 'capacity/employees',
  storeName: 'users'
})
export class User extends BaseEntity {

  // @Expose()
  // @IsNotEmpty()
  // username: string;

  @Expose()
  @ValidateIf(user => !user.id)
  @IsNotEmpty()
  password: string;

  @Exclude()
  get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  @Expose()
  @IsNotEmpty()
  firstName: string;

  @Expose()
  @IsNotEmpty()
  lastName: string;

  @Expose()
  @IsNotEmpty()
  email: string;
  //
  // @Expose({name: 'operation_ids'})
  // operations: Array<number> = [];

  @Expose()
  avatar: string;


}

