import {registerDecorator, ValidationOptions, ValidationArguments} from 'class-validator';

export function HasID(validationOptions?: ValidationOptions) {
  return function(object: Object, propertyName: string) {
    registerDecorator({
      name: 'hasID',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate(value: any, args: ValidationArguments) {
          return value && !!value.id;
        }
      }
    });
  };
}
