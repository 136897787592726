import {Exclude, Expose, Transform, Type} from 'class-transformer';
import {BaseEntity} from '../base/base-entity';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {Country} from '../country/country.entity';
import {HolosenEntityChild} from '../../decorators/HolosenEntityChild';
import {IsNotEmpty, ValidateNested} from 'class-validator';


export enum AddressType {
  BILLING = 1,
  SHIPPING = 2
}


@Exclude()
@HolosenEntityModel({
  apiPrefix: 'addresses',
  storeName: 'addresses'
})
export class Address extends BaseEntity {

  @Expose()
  @IsNotEmpty()
  type: number;

  @Expose()
  @IsNotEmpty()
  name: string;

  @Expose()
  @IsNotEmpty()
  addressLine: string;

  @Expose()
  state: string;

  @Expose()
  @IsNotEmpty()
  city: string;

  @Expose()
  zipCode: string;

  @HolosenEntityChild(() => Country)
  @Expose()
  @Type(() => Country)
  @IsNotEmpty()
  @ValidateNested()
  @Transform((c) => {
    return c.id
  }, {
    groups: ['toAPI']
  })
  country: Country;

  @Expose()
  businessPartner: number;


}

