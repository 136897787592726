import {TimeEvent} from '../../time-event.entity';
import {HolosenEntityModel} from '../../../../decorators/HolosenEntityModel';
import {Expose} from 'class-transformer';
import {MaintenanceDetails, TimeEventType} from '../../time-event-details';


@HolosenEntityModel({
  apiPrefix: 'capacity/time-events',
  storeName: 'maintenances'
})
export class Maintenance extends TimeEvent {

  @Expose()
  type = TimeEventType.MAINTENANCE;

  @Expose()
  details: MaintenanceDetails = new MaintenanceDetails();
}
