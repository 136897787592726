import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from './menu-item.directive';
import {NzMenuItemDirective} from 'ng-zorro-antd';
import {HolosenAuthenticationService} from '../../../../core/services/holosen-authentication.service';
import {Router} from '@angular/router';


const MENU_ITEM_LIST: Array<MenuItem> = [
  {
    text: 'Anasayfa',
    url: '/dashboard',
    icon: 'fa fa-home'
  },
  {
    text: 'Eğitim Yönetimi',
    url: '/trainings',
    icon: 'fa fa-chalkboard-teacher'
  },
  {
    text: 'Değişiklik Yönetimi',
    url: '/changes',
    icon: 'fa fa-exchange-alt'
  },
  {
    text: 'DÖF Yönetimi',
    url: '/cpas',
    icon: 'fa fa-tasks'
  },
  {
    text: 'KPI Yönetimi',
    url: '/kpis',
    icon: 'far fa-check-square'
  },
  {
    text: 'Risk & Fırsat Yönetimi',
    url: '/risks',
    icon: 'fa fa-search'
  },
  {
    text: 'Toplantı Yönetimi',
    url: '/meetings',
    icon: 'far fa-handshake'
  },{
    text: 'Kalite Hedefleri',
    url: '/quality-objectives',
    icon: 'fa fa-bullseye'
  },
  {
    text: 'Personel',
    url: '/capacity/employees',
    icon: 'fa fa-users'
  }
];


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']
})
export class SidebarComponent implements OnInit {

  private _isCollapsed: boolean;

  @Input()
  set isCollapsed(value) {
    this._isCollapsed = value;
    console.log('isCollapsed', this._isCollapsed);
  };

  get isCollapsed() {
    return this._isCollapsed;
  }

  menuItemList: Array<MenuItem> = [];

  constructor(private authService: HolosenAuthenticationService,
              private router: Router) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.menuItemList = MENU_ITEM_LIST;
    }, 0);
  }

  logout = () => {
    this.authService.logout().subscribe(_ => {
      this.router.navigate(['/auth/login']);
    });
  };


}
