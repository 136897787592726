import {Component, OnDestroy, OnInit} from '@angular/core';
import {HolosenLayoutService} from '../services/holosen-layout.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-authenticated-layout',
  templateUrl: './authenticated-layout.component.html',
  styleUrls: ['./authenticated-layout.component.less']
})
export class AuthenticatedLayoutComponent implements OnInit, OnDestroy {

  private isCollapsed$ = this.layoutService.isSidebarCollapsed$;
  isCollapsed: boolean = false;
  onDestroy$ = new Subject();


  constructor(private layoutService: HolosenLayoutService) {}

  ngOnInit() {

    this.isCollapsed$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((isCollapsed) => {
        this.isCollapsed = isCollapsed;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }


  onToggleSidebarCollapse = (isCollapsed) => {
    this.layoutService.setSidebarCollapsed(!isCollapsed);
  };

}
