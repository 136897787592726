import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {HolosenLocalStorageService} from './holosen-local-storage.service';
import {AppConfig} from '../../app.config';
import {plainToClass} from 'class-transformer';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {HolosenApiResponse} from '../types/holosen-api-response';
import {TokenData} from '../types/token-data';
import {HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {InterceptorSkipHeader} from '../interceptors/auth.interceptor';
import {HolosenHttpService} from './holosen-http.service';
import {User} from '../../state-management/entities/user/user.entity';
import * as camelcaseKeys from 'camelcase-keys';


@Injectable({
  providedIn: 'root'
})
export class HolosenAuthenticationService {


  constructor(private storageService: HolosenLocalStorageService,
              private http: HolosenHttpService) {
  }


  login = (credentials: { email: string, password: string, remember: boolean }): Observable<any> => {

    return this.isLoggedIn().pipe(
      switchMap((isLoggedIn) => {
        if (isLoggedIn) {
          return of(false);
        }

        this.storageService.delete(AppConfig.STORAGE_KEYS.TOKEN_STORAGE_KEY, true);

        const requestBody = {
          email: credentials.email,
          password: credentials.password,
          remember: credentials.remember
        };

        const headers = new HttpHeaders().append(InterceptorSkipHeader, 'true').append('Content-type', 'application/json');

        return this.http.post(AppConfig.URL.API + 'authentication/token/', requestBody, {headers}).pipe(
          switchMap((loginResponse: any) => {
            console.log(`[loginResponse] `, loginResponse);
            loginResponse = {
              ...loginResponse,
              user: camelcaseKeys(loginResponse.user, {deep: true})
            };


            return (this.storageService.set(AppConfig.STORAGE_KEYS.TOKEN_STORAGE_KEY, plainToClass(TokenData, loginResponse, {excludePrefixes: ['_']})) as Observable<any>).pipe(
              map(() => true)
            );
          }),
          catchError(error => {
            return of(error);
          })
        );

      })
    );
  };


  logout = (): Observable<any> => {
    return this.isLoggedIn().pipe(
      switchMap((isLoggedIn) => {
        if (!isLoggedIn) {
          return of(false);
        }

        return this.http.post(AppConfig.URL.API + 'authentication/revoke-token/', {}).pipe(
          switchMap((httpResponse: HolosenApiResponse) => {
            return (this.storageService.delete(AppConfig.STORAGE_KEYS.TOKEN_STORAGE_KEY) as Observable<any>);
          }),
          catchError(error => throwError(error))
        );
      })
    );

  };


  isLoggedIn = (): Observable<boolean> => {
    return this.storageService.has(AppConfig.STORAGE_KEYS.TOKEN_STORAGE_KEY).pipe(
      // switchMap((hasItem: boolean) => of(hasItem))
    );
  };

  getCurrentUser = () => {
    return this.storageService.get(AppConfig.STORAGE_KEYS.TOKEN_STORAGE_KEY).pipe(
      map(item => plainToClass(User, item.user))
    );
  };

}
