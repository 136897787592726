import {NgModule} from '@angular/core';
import {PlanbuLocalVariableDirective} from './directives/planbu-local-variable.directive';
import {CommonModule} from '@angular/common';
import {PlanbuLoadingComponent} from './components/planbu-loading/planbu-loading.component';
import {PlanbuContrastFontPipe} from './pipes/planbu-contrast-font.pipe';
import {PlanbuNoDataComponent} from './components/planbu-no-data/planbu-no-data.component';
import {NgZorroAntdModule} from 'ng-zorro-antd';
import {PlanbuEnumToArrayPipe} from './pipes/planbu-enum-to-array.pipe';
import {PlanbuSanitizerPipe} from './pipes/planbu-sanitizer.pipe';
import {NgPipesModule} from 'angular-pipes';
import {PlanbuAutofocusDirective} from './directives/planbu-autofocus.directive';
import {PlanbuAutocompleteComponent} from './components/planbu-autocomplete/planbu-autocomplete.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TimepickerConfig, TimepickerModule} from 'ngx-bootstrap';
import {PlanbuMomentToDatePipePipe} from './pipes/moment-to-date.pipe';
import {GetBgColorPipe} from './common/get-bg-color.pipe';
import {PlanbuSelectColorComponent} from './components/planbu-select-color/planbu-select-color.component';
import {PlanbuAutocompleteSelectionComponent} from './components/planbu-autocomplete-selection/planbu-autocomplete-selection.component';
import {TranslateModule} from '@ngx-translate/core';
import {PlanbuPositiveIntegerInputDirective} from './directives/planbu-positive-integer-input.directive';
import {AttachmentUpsertComponent} from './components/attachment-upsert/attachment-upsert.component';
import {FormsModule} from '@angular/forms';
import {ActivityUpsertComponent} from './components/activity-upsert/activity-upsert.component';
import {StatusPropsPipe} from './pipes/status-props.pipe';


export function getTimepickerConfig(): TimepickerConfig {
  return {
    ...new TimepickerConfig(),
    ...{
      showMeridian: false,
      readonlyInput: false,
      mousewheel: true,
      showMinutes: true,
      showSeconds: false,
      minuteStep: 1
    }
  };
}


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgZorroAntdModule,
    NgPipesModule,
    DragDropModule,
    TimepickerModule.forRoot(),
    TranslateModule.forChild(),
  ],
  declarations: [
    PlanbuLocalVariableDirective,
    PlanbuLoadingComponent,
    PlanbuContrastFontPipe,
    PlanbuNoDataComponent,
    PlanbuEnumToArrayPipe,
    PlanbuSanitizerPipe,
    PlanbuAutofocusDirective,
    PlanbuMomentToDatePipePipe,
    PlanbuAutocompleteComponent,
    PlanbuAutocompleteSelectionComponent,
    GetBgColorPipe,
    PlanbuSelectColorComponent,
    PlanbuPositiveIntegerInputDirective,
    AttachmentUpsertComponent,
    ActivityUpsertComponent,
    StatusPropsPipe
  ],
  exports: [
    CommonModule,
    PlanbuLocalVariableDirective,
    PlanbuLoadingComponent,
    PlanbuContrastFontPipe,
    PlanbuNoDataComponent,
    NgZorroAntdModule,
    PlanbuSanitizerPipe,
    NgPipesModule,
    PlanbuAutofocusDirective,
    PlanbuAutocompleteComponent,
    DragDropModule,
    TimepickerModule,
    PlanbuMomentToDatePipePipe,
    GetBgColorPipe,
    PlanbuSelectColorComponent,
    PlanbuAutocompleteSelectionComponent,
    PlanbuPositiveIntegerInputDirective,
    AttachmentUpsertComponent,
    ActivityUpsertComponent,
    StatusPropsPipe
  ],
  providers: [
    {provide: TimepickerConfig, useFactory: getTimepickerConfig}
  ]
})
export class CoreModule {
}
