import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppConfig} from '../../../app.config';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-planbu-no-data',
  templateUrl: './planbu-no-data.component.html',
  styleUrls: ['./planbu-no-data.component.less']
})
export class PlanbuNoDataComponent implements OnInit {

  returnURL = AppConfig.DEFAULT_RETURN_URL;

  @Input()
  primaryButtonText: string;

  @Input()
  secondaryButtonText: string;

  @Input()
  showPrimary: boolean = true;

  @Input()
  showSecondary: boolean = true;

  @Input()
  onSecondaryClick: (...args) => any = null;

  @Input()
  noDataTitle;

  @Input()
  noDataSubTitle;

  @Output()
  primaryClick: EventEmitter<void> = new EventEmitter<void>();


  constructor(private router: Router,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    this.setTranslations();
    this.translateService.onLangChange.subscribe(_ => this.setTranslations());
  }

  setTranslations = () => {
    this.primaryButtonText = this.translateService.instant('COMMON.ADD_NEW');
    this.secondaryButtonText = this.translateService.instant('COMMON.GO_TO_DASHBOARD');
    this.noDataTitle = this.translateService.instant(this.noDataTitle || 'COMMON.NO_DATA_TO_SHOW');
    this.noDataSubTitle = this.translateService.instant(this.noDataSubTitle || 'COMMON.PLEASE_CHECK_FILTERS');
  };

  clickPrimary = () => this.primaryClick.emit();
  clickSecondary = () => this.onSecondaryClick ? this.onSecondaryClick() : this.router.navigate([this.returnURL]);

}
