import {Exclude, Expose, Transform, Type} from 'class-transformer';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {BasePlannable} from '../base-plannable/base-plannable.entity';
import {IsNotEmpty} from 'class-validator';
import {User} from '../user/user.entity';
import {Media} from '../media/media.entity';
import {HolosenEntityChild} from '../../decorators/HolosenEntityChild';
import {Activity, ActivityTypesEnum, ACTYIVITY_SOURCE_TYPE} from '../activity/activity.entity';


export const CPA_SOURCE_TYPES = {
  INTERNAL_AUDIT: {
    name: 'İç Denetim',
    value: 1
  },
  EXTERNAL_AUDIT: {
    name: 'Dış Denetim',
    value: 2
  },
  SUPPLIER_AUDIT: {
    name: 'Tedarikçi Denetimi',
    value: 3
  },
  OHS: {
    name: 'İSG Uygunsuzluğu',
    value: 4
  },
  TRAINING_REVIEW: {
    name: 'Eğitim Etkinliği Sonucu',
    value: 5
  },
  CUSTOMER_COMPLAIN: {
    name: 'Müşteri Şikayeti',
    value: 6
  },
  EMPLOYEE_OBSERVATION: {
    name: 'Personel Gözlemi',
    value: 7
  },
  PERFORMANCE_REVIEW: {
    name: 'Performans Değerlendirme',
    value: 8
  }
}

@Exclude()
@HolosenEntityModel({
  apiPrefix: 'planning/cpas',
  storeName: 'cpas'
})
export class Cpa extends BasePlannable {

  @Expose()
  @Type(() => Activity)
  @HolosenEntityChild(() => Activity)
  activities: Array<Activity> = [];

  @Expose()
  @IsNotEmpty()
  @Type(() => User)
  @Transform((trainee) => trainee.id, {groups: ['toAPI']})
  @HolosenEntityChild(() => User)
  responsible: User;

  @Expose()
  @Type(() => Media)
  @HolosenEntityChild(() => Media)
  attachments: Array<Media> = [];

  @Expose()
  @IsNotEmpty()
  source: number;

  @Expose()
  isRepetitive: boolean = false;

  @Expose()
  sourceName: string;


}

