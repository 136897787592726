import {Exclude, Expose} from 'class-transformer';
import {BaseEntity} from '../base/base-entity';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {IsNotEmpty} from 'class-validator';

@Exclude()
@HolosenEntityModel({
  apiPrefix: 'inventory/units',
  storeName: 'units'
})
export class Unit extends BaseEntity {

  @Expose()
  @IsNotEmpty()
  name: string;


  @Expose()
  @IsNotEmpty()
  code: string;

  @Expose()
  description: string;


}

