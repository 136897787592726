import {Injectable} from '@angular/core';
import {JSONSchema, StorageMap} from '@ngx-pwa/local-storage';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HolosenLocalStorageService {

  constructor(private storageMap: StorageMap) {
  }


  size: Observable<number> = this.storageMap.size;

  get = (index: string, schema?: JSONSchema): Observable<any> => {
    return this.has(index).pipe(
      switchMap((hasItem: boolean) => hasItem ? this.storageMap.get(index, schema) : of(false))
    );
  };

  set(index: string, value: any, subscribe = false): Observable<any> | void {
    const observable = this.storageMap.set(index, value);
    if (!subscribe) {
      return observable;
    }
    observable.subscribe();
  }

  delete(index: string, subscribe = false): Observable<any> | void {
    const observable = this.storageMap.delete(index);
    if (!subscribe) {
      return observable;
    }
    observable.subscribe();
  }

  clear(subscribe = false): Observable<any> | void {
    const observable = this.storageMap.clear();
    if (!subscribe) {
      return observable;
    }
    observable.subscribe();
  }

  has(index: string): Observable<boolean> {
    return this.storageMap.has(index);
  }

  keys(): Observable<string> {
    return this.storageMap.keys();
  }

}
