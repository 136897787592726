import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'planbuEnumToArray'
})
export class PlanbuEnumToArrayPipe implements PipeTransform {

  transform(data: Object) {
    return Object.keys(data);
  }

}
