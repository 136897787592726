import {NgModule} from '@angular/core';
import {AuthenticatedLayoutComponent} from './authenticated-layout.component';
import {SharedImportsModule} from '../../shared-imports.module';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {HeaderComponent} from './components/header/header.component';
import {MenuItemComponent} from './components/sidebar/menu-item/menu-item.component';
import { MenuItemDirective } from './components/sidebar/menu-item.directive';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  imports: [
    SharedImportsModule,
    TranslateModule.forChild()
  ],
  declarations: [
    AuthenticatedLayoutComponent,
    SidebarComponent,
    MenuItemComponent,
    HeaderComponent,
    MenuItemDirective
  ],
  exports: [
    AuthenticatedLayoutComponent
  ]

})
export class AuthenticatedLayoutModule {
}
