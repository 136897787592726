import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PublicRoutingModule} from './public-routing.module';
import {AuthenticatedRoutingModule} from './authenticated-routing.module';


@NgModule({
  imports: [RouterModule.forRoot([])],
  exports: [
    RouterModule,
    PublicRoutingModule,
    AuthenticatedRoutingModule
  ]
})
export class AppRoutingModule { }
