import { Pipe, PipeTransform } from '@angular/core';
import {Moment} from 'moment';

@Pipe({
  name: 'momentToDate'
})
export class PlanbuMomentToDatePipePipe implements PipeTransform {

  transform(value: Moment): any {
    return value.toDate();
  }

}
