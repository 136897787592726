import {Exclude, Expose, Transform, Type} from 'class-transformer';
import {HolosenEntityModel} from '../../decorators/HolosenEntityModel';
import {BaseEntity} from '../base/base-entity';
import {IsDefined, IsNotEmpty, IsNumber, IsPositive, Min, ValidateIf} from 'class-validator';
import {Operation} from '../operation/operation.entity';
import {HolosenEntityChild} from '../../decorators/HolosenEntityChild';
import {User} from '../user/user.entity';


export enum WorkCenterProcessingType {
  SINGLE = 1 as any,
  MULTI = 2 as any,
  OTHER = 3 as any,
}


export enum WorkCenterCapacityType {
  CONSTANT = 1 as any,
  VARIABLE = 2 as any,
  OTHER = 3 as any,
}

@Exclude()
@HolosenEntityModel({
  apiPrefix: 'capacity/work-centers',
  storeName: 'workCenters'
})
export class WorkCenter extends BaseEntity {

  @Expose()
  @IsNotEmpty()
  code: string;

  @Expose()
  @IsNotEmpty()
  name: string;

  @Expose()
  @IsNotEmpty()
  color: string;

  @Expose()
  @Type(() => Operation)
  @HolosenEntityChild(() => Operation)
  @Transform((operations) => operations.map(op => op.id), {groups: ['toAPI']})
  operations: Operation[] = [];

  @Expose()
  @Type(() => User)
  @HolosenEntityChild(() => User)
  @Transform((employees) => employees.map(emp => emp.id), {groups: ['toAPI']})
  employees: User[] = [];


  @Expose()
  @IsNotEmpty()
  processingType: number = WorkCenterProcessingType.SINGLE;

  @Expose()
  @IsNotEmpty()
  @ValidateIf((wc: WorkCenter) => wc.processingType === WorkCenterProcessingType.MULTI)
  capacityType: number = WorkCenterCapacityType.CONSTANT;


  @ValidateIf((wc: WorkCenter) => wc.capacityType === WorkCenterCapacityType.CONSTANT && wc.processingType === WorkCenterProcessingType.MULTI)
  @IsPositive()
  @IsNotEmpty()
  @Expose()
  capacity = 1;


  @Exclude()
  hasOperation = (operationID: number) => {
    return this.operations.some(operation => operation.id === operationID);
  };

  @Exclude()
  hasEmployee = (employeeID: number) => {
    return this.employees.some(employee => employee.id === employeeID);
  };

}

