import {Injectable} from '@angular/core';
import {HolosenLocalStorageService} from '../../core/services/holosen-local-storage.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

interface LayoutStorageModel {
  sidebar?: {
    collapsed: boolean;
  };
}

@Injectable({
  providedIn: 'root'
})
export class HolosenLayoutService {

  readonly LAYOUT_LOCAL_STORAGE_KEY = 'layout';


  private isSidebarCollapsedSubject = new BehaviorSubject<boolean>(false);
  isSidebarCollapsed$ = this.isSidebarCollapsedSubject.asObservable();

  constructor(private localStorageService: HolosenLocalStorageService) {
  }


  private getLayoutStoreValue = (): Observable<LayoutStorageModel | false> => {
    return this.localStorageService.get(this.LAYOUT_LOCAL_STORAGE_KEY);
  };

  subscribeToSidebarStatus = (): Observable<boolean> => {
    return this.getLayoutStoreValue().pipe(
      map((item: LayoutStorageModel | false) => item === false ? false : item.sidebar.collapsed),
      tap(isCollapsed => {
        this.isSidebarCollapsedSubject.next(isCollapsed);
      })
    );
  };

  setSidebarCollapsed = (isCollapsed: boolean) => {
    this.isSidebarCollapsedSubject.next(isCollapsed);
    this.getLayoutStoreValue().subscribe((layoutStoreValue: LayoutStorageModel | false) => {
      let value = {
        sidebar: {
          collapsed: isCollapsed
        }
      } as LayoutStorageModel;

      if (layoutStoreValue !== false) {
        value = {
          ...layoutStoreValue,
          sidebar: {
            ...layoutStoreValue.sidebar,
            collapsed: isCollapsed
          }
        };
      }

      this.localStorageService.set(this.LAYOUT_LOCAL_STORAGE_KEY, value, true);
    });
  };
}
