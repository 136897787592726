import {Injectable} from '@angular/core';
import {BaseEntityService} from '../../services/base-entity.service';
import {User} from './user.entity';
import {AppConfig} from '../../../app.config';
import {holosenEntityMetadataStorage} from '../../metadata/holosen-entity-metadata-storage';

@Injectable({
  providedIn: 'root'
})
export class UserEntityService extends BaseEntityService<User> {

  protected readonly STATE_MODEL = User;

  constructor() {
    super();
  }

}
