import {Injectable} from '@angular/core';
import {BaseEntityService} from '../../services/base-entity.service';
import {Media} from './media.entity';

@Injectable({
  providedIn: 'root'
})
export class MediaEntityService extends BaseEntityService<Media> {

  protected readonly STATE_MODEL = Media;

  constructor() {
    super();
  }

}
