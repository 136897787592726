import {Component, Input, OnInit, Optional, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Subject} from 'rxjs';
import {NzMessageService, NzModalComponent, NzModalRef, NzModalService, NzNotificationService} from 'ng-zorro-antd';
import {TranslateService} from '@ngx-translate/core';
import {takeUntil} from 'rxjs/operators';
import {
  Activity,
  ACTIVITY_TYPES,
  ACTYIVITY_SOURCE_TYPE
} from '../../../state-management/entities/activity/activity.entity';
import {ActivityEntityService} from '../../../state-management/entities/activity/activity-entity.service';
import {UserEntityService} from '../../../state-management/entities/user/user-entity.service';
import {User} from '../../../state-management/entities/user/user.entity';

@Component({
  selector: 'app-activity-upsert',
  templateUrl: './activity-upsert.component.html',
  styleUrls: ['./activity-upsert.component.less']
})
export class ActivityUpsertComponent implements OnInit {

  @Input()
  entityService;

  @Input()
  activityID: number;

  @Input()
  plannableID: number;

  activity: Activity = new Activity();

  @Input()
  activitySourceType: ACTYIVITY_SOURCE_TYPE;

  selectedFile = [];

  @ViewChild('upsertForm', {static: true})
  upsertForm: NgForm;


  private onDestroy$: Subject<void> = new Subject<void>();
  isUpsertLoading = false;

  modalRefInstance: NzModalComponent;

  ACTIVITY_TYPES = ACTIVITY_TYPES;
  ACTIVITY_SOURCE_TYPES = ACTYIVITY_SOURCE_TYPE;
  HIDDEN_TYPES = [
    ACTYIVITY_SOURCE_TYPE.TRAINING,
    ACTYIVITY_SOURCE_TYPE.KPI,
    ACTYIVITY_SOURCE_TYPE.MEETING,
    ACTYIVITY_SOURCE_TYPE.RFA
  ]

  responsibleAutocompleteDataSource = this.userService.autocomplete;


  constructor(@Optional() private modalRef: NzModalRef<ActivityUpsertComponent>,
              private activityService: ActivityEntityService,
              private modalService: NzModalService,
              private notificationService: NzNotificationService,
              private messageService: NzMessageService,
              private userService: UserEntityService,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    this.modalRefInstance = this.modalRef.getInstance();

    if (!this.activity.id) {
      switch (this.activitySourceType) {
        case ACTYIVITY_SOURCE_TYPE.CPA:
          break;
        case ACTYIVITY_SOURCE_TYPE.TRAINING:
          this.activity.type = ACTIVITY_TYPES[ACTYIVITY_SOURCE_TYPE.TRAINING].TRAINING.value;
          break;
        case ACTYIVITY_SOURCE_TYPE.CHANGE:
          break;
        case ACTYIVITY_SOURCE_TYPE.KPI:
          this.activity.type = ACTIVITY_TYPES[ACTYIVITY_SOURCE_TYPE.KPI].KPI.value;
          break;
        case ACTYIVITY_SOURCE_TYPE.MEETING:
          this.activity.type = ACTIVITY_TYPES[ACTYIVITY_SOURCE_TYPE.KPI].KPI.value;
          break;
        case ACTYIVITY_SOURCE_TYPE.RFA:
          this.activity.type = ACTIVITY_TYPES[ACTYIVITY_SOURCE_TYPE.RFA].RFA.value;
          break;

      }
    }

    if (!this.activityID) {
      this.setModalTitle();
    } else {
      this.activityService.selectByID(this.activityID)
        .pipe(
          takeUntil(this.onDestroy$)
        )
        .subscribe((selectedEntity) => {
          this.activity = selectedEntity;
          this.setModalTitle();
        });
    }
  }


  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }


  private setModalTitle = () => {
    if (this.modalRef) {
      const modalInstance = this.modalRefInstance;
      if (!modalInstance.nzTitle) {
        if (this.activity.id) {
          modalInstance.nzTitle = 'Faaliyet Düzenle';
        } else {
          modalInstance.nzTitle = 'Yeni Faaliyet Oluştur';

        }
      }
    }
  };


  upsertActivity = () => {
    this.isUpsertLoading = true;
    this.modalRefInstance.nzClosable = false;
    this.activity.validate()
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe((isValid) => {
        if (!isValid) {
          console.log(`[this.activity.errors] `, this.activity.errors);
          this.isUpsertLoading = false;
          this.modalRefInstance.nzClosable = true;

          return;
        }


        this.entityService.upsertActivityRemote(this.plannableID, this.activity).subscribe(
          (updateRemoteResult) => {
            this.upsertForm.form.markAsUntouched();
            this.messageService.success('Faaliyet Kaydedildi');
            this.modalRef.triggerOk();
            this.cancel();
            return;
          },
          (error => {
            this.notificationService.error('Bir Hata Oluştu', 'Faaliyet kaydedilemedi');
            this.isUpsertLoading = false;
            this.modalRefInstance.nzClosable = true;
          })
        );

      });

  };


  cancel = () => {
    this.modalRef.triggerCancel();
  };

  onCancel = () => {
    return new Promise((resolve) => {
      if (this.isUpsertLoading) {
        resolve(false);
      }
      if (this.upsertForm.touched) {
        this.modalService.confirm({
          nzTitle: 'Emin misiniz?',
          nzContent: 'Kaydedilmemiş bilgiler kaybolacak',
          nzOkText: 'Evet',
          nzCancelText: 'Hayır',
          nzOnOk: () => resolve(true)
        });
      } else {
        resolve(true);
      }
    });

  };


  responsibleSelected = (employee: User) => {
    this.activity.responsible = employee;
    this.activity.resetError('responsible');
  };

  toEmployee = (item: any) => {
    return item as User;
  };
}
