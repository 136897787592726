import './address/address.entity';
import './business-partner/business-partner.entity';
import './contact/contact.entity';
import './country/country.entity';
import './currency/currency.entity';
import './media/media.entity';
import './operation/operation.entity';
import './product/product.entity';
import './product-requirement/product-requirement.entity';
import './raw-material/raw-material.entity';
import './sales-order/sales-order.entity';
import './sales-order-item/sales-order-item.entity';
import './scrap-material/scrap-material.entity';
import './shift/shift.entity';
import './tag/tag.entity';
import './tax/tax.entity';
import './time-event/time-event.entity';
import './time-event/subtypes/holiday/holiday';
import './time-event/subtypes/leave/leave.entity';
import './time-event/subtypes/overtime/overtime.entity';
import './time-event/subtypes/maintenance/maintenance.entity';
import './unit/unit.entity';
import './user/user.entity';
import './work-center/work-center.entity';
import './work-in-process/work-in-process.entity';

import './training/training.entity';
import './cpa/cpa.entity';
import './change/change.entity';
import './activity/activity.entity';
import './kpi/kpi.entity';
import './meeting/meeting.entity';
import './risk/risk.entity';
import './quality-objective/quality-objective.entity';

