<ul nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="isCollapsed" class="main-menu" [ngClass]="{'collapsed': isCollapsed}">

  <!--  <app-side-menu-item *ngFor="let menuItem of menuItemList" [menuItem]="menuItem"></app-side-menu-item>-->

  <ng-container *ngFor="let menuItem of menuItemList">
    <li appMenuItem #childlessMenuItem="appMenuItem" nz-menu-item
        class="main-menu-item"
        *ngIf="menuItem && (!menuItem.children || menuItem.children.length === 0)"
        [nzSelected]="childlessMenuItem.isActive"
        [routerLink]="[menuItem.url]"
        nz-popover
        nzPopoverPlacement="right"
        nzOverlayClassName="holosen-sidebar-tooltip"
        [nzContent]="isCollapsed ? (menuItem.text | translate) : ''"
        routerLinkActive>
      <i class="fa-fw" [ngClass]="menuItem.icon" style="margin-right: 8px"></i>
      <span *ngIf="!isCollapsed">{{menuItem.text | translate}}</span>
    </li>
    <li appMenuItem #menuItemWithChild="appMenuItem" nz-submenu *ngIf="menuItem.children"
        class="main-menu-item"
        [nzOpen]="menuItemWithChild.isActive && !isCollapsed"
        [ngClass]="{'ant-menu-item-selected': menuItemWithChild.isActive && isCollapsed}"
        [nzTitle]="subMenuTitleTemplate"
        nzMenuClassName="holosen-submenu"
    >
      <ng-template #subMenuTitleTemplate>
        <i class="fa-fw" [ngClass]="menuItem.icon" style="margin-right: 8px"></i>
        <span *ngIf="!isCollapsed">{{menuItem.text |translate}}</span>
      </ng-template>
      <ul>
        <ng-container *ngIf="isCollapsed">
          <li class="holosen-submenu-title">
            <div>{{menuItem.text | translate}}</div>
          </li>
          <li nz-menu-divider style="margin-top: 0 !important;"></li>
        </ng-container>
        <ng-container *ngFor="let submenuItem of menuItem.children">
          <li nz-menu-item [nzSelected]="childMenuItem.isActive" class="holosen-submenu-item" [ngClass]="{'collapsed': isCollapsed}">
            <a [routerLink]="[(menuItem.prefix ? menuItem.prefix : '') + submenuItem.url]"
               routerLinkActive
               #childMenuItem='routerLinkActive'>
              <i class="fa-fw" [ngClass]="submenuItem.icon" *ngIf="isCollapsed" style="margin-right: 10px"></i>
              {{submenuItem.text | translate}}
            </a>
          </li>
        </ng-container>
      </ul>
    </li>
  </ng-container>
  <!--  <li nz-menu-divider></li>-->
  <!--  <li nz-menu-item-->
  <!--      (click)="logout()"-->
  <!--      nz-popover-->
  <!--      nzPopoverPlacement="right"-->
  <!--      [nzContent]="isCollapsed ? 'Sign Out' : ''"-->
  <!--  >-->
  <!--    <i class="fa-fw fa fa-sign-out-alt" style="margin-right: 8px"></i>-->
  <!--    <span *ngIf="!isCollapsed">Sign Out</span>-->
  <!--  </li>-->

</ul>

